import React from 'react'
import './ContentRead.css'
const Privacy = () => {
  return (
    <div>
        <div className='titleRead'>
        Política de privacidad
        </div>
        <div className='contentRead'>Con la intención de identificar a cada usuario y mostrarle contenido a su perfil se crea una cuenta con información suya como lo es correo electrónico, nombre, imágenes y contraseña de forma que se identifique como usuario dentro de la plataforma. Esta información puede provenir de usted directamente o de un medio externo como Facebook al comento que usted lo utiliza como medio para iniciar sesión.
Con la intención de mejorar el contenido y personalizarlo guardamos interacciones en la plataforma (búsquedas, comentarios, calificaciones y visitas) de forma que podemos hacer recomendaciones similares y mostrarle contenido con una certeza mas amplia de que le agrade.
Su información será únicamente utilizada para identificarlo y mostrarle información acorde a su perfil, pero este documento puede cambiar, en caso de que así sea se le notificara para que pueda aceptar la nueva política de privacidad.

</div>
       
    </div>
  )
}

export default Privacy
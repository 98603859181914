import React, { useContext, useEffect } from 'react'
import './Menu.css';
import close from './../../assets/close.svg';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ImageProfileMini } from './MenuComponent.Styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faRightToBracket } from '@fortawesome/free-solid-svg-icons'

const MenuComponent = ({setMenus, logOut}) => {
  const navigate = useNavigate();

  const data = useContext(AuthContext);
  useEffect(() => {
    console.log('data')
    console.log(data)
  }, [data])
  const openLogIn = ()=> {
    setMenus(false)
    navigate('/login')
    //setLogin(true)
  }
  return (
    <div className='backD'>
        <div className='menu'>
          <div className='optionsSection'>
            <div className='userSection'>
              <div className='headOptionsContent'>

                <div className='titleMenu'>Opciones
                </div>
                <div className='closeContent'>
                <img src={close} className='closeAction' onClick={() => setMenus(false)}
                alt="close" />
                </div>
              </div>
                {
                    data.logIn ? (<div className='fSection'>
                    <div className='userLoged'>

                    <ImageProfileMini image={data.user.img} />
                    <div className='nameUser'>{data.user.name}</div>
                    </div>
                    <FontAwesomeIcon icon={faGear} className='iconGearSettings'  size="3x" color='#fff'/>

                    </div>):
                    (<div className='flSection'>
                    <div className='logIn' onClick={openLogIn}>Iniciar sesion</div>
    
                    </div>)
                }
                
                
            </div>
            </div>
            <div className='closeSection'>
            {data.logIn &&
                <button className='btnClose' onClick={() => logOut()}>
                    Cerrar
                    <FontAwesomeIcon icon={faRightToBracket} className='iconGearSettings'  size="1x" color='#ec0e0e'/>

                </button>}
            </div>
           
        </div>
    </div>
    
  )
}

export default MenuComponent
import React, {useEffect, useRef, useState} from 'react'
import './StepTwoComponent.css'
import Location from './../../assets/location.svg';
import { GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';
import AutoCompleteComponent from '../AutoCompleteComponent/AutoCompleteComponent';
import { getLocation } from 'graphql';
//import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es");
Geocode.setApiKey("AIzaSyAdNAIpLQ-tsmstJzaR1573wdctTDJQyZo");
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

const containerStyle = {
  width: '100%',
  height: '100%'
};
const StepTwoComponent = ({initial={
  lat: 25.7294336, lng:-100.1816064
}, setForm, form}) => {
  const [/** @type google.maps.Map */map, setMap] = React.useState(null)
  
  const [center, setCenter] = useState(null)
  const [directions, setDirections] = useState({value:'', showAddress: '', address_components: [], street: '', number: ''})
  const addressRef = useRef();
  console.log("initial")
  console.log(initial)
  useEffect(() => {
    setForm({...form, directions})
  }, [directions])
  useEffect(() => {
    getLocation();
  }, [])
  useEffect(() => {
    if(map){
      console.log('if map')

      map.panTo(center)
      map.setZoom(16)
    }
  }, [center])
  const dataChange = (data)=> {
    console.log(data.target.value)
    console.log(directions.address_components[0].types[0])
    console.log(data.target.name)
    if(directions.address_components[0].types[0] == 'street_number' && [data.target.name] == 'number') {
      console.log('si entramos')

      setDirections({...directions,[data.target.name]: data.target.value, address_components: 
      directions.address_components.map(adc=> {
        let ad = adc;
        if(adc.types[0] == 'street_number') {
          ad = {
            long_name: data.target.value,
            short_name: data.target.value,
            types: ['street_number']
          }
        } 
        return ad
      })}) 
    } else {
      setDirections({...directions, [data.target.name]: data.target.value})

    }
  }
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    ////

    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(response);
        setCenter({lat:position.coords.latitude ,lng:position.coords.longitude})
        let currently_address = response.results[0].address_components;
        console.log(currently_address.filter(comp=> comp.types[0] == 'street_number')[0].short_name)
        let currently = currently_address.filter(comp=> comp.types[0] == 'street_number')[0].short_name;
        setDirections({...directions, value: address, showAddress:address,
          coords: {lat:position.coords.latitude ,lng:position.coords.longitude}, 
          address_components: response.results[0].address_components, 
          street: response.results[0].address_components[1].long_name,
        number: currently})
      },
      (error) => {
        console.error(error);
      }
    );

    ////
    });
  }
  const onLoad = React.useCallback(function callback(map) {
    
    
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
    setCenter(initial)
  }, [])
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAdNAIpLQ-tsmstJzaR1573wdctTDJQyZo"
  })
  
  const moveStart = ()=> {
    console.log('getLocation')
    getLocation()
      
    
  }

  return (
    <div className='bodyNP'>
        <div className='contentMap'>

        {/* */}
        <div className='sectionMap'>
        {
          isLoaded && (
            <GoogleMap
            onBoundsChanged={()=>{console.log('algo cambio')}}
            onClick={(data)=>{
             let coords = {
              lat: data.latLng.lat(),
               lng: data.latLng.lng()
            }
              setCenter(coords)
              Geocode.fromLatLng(coords.lat, coords.lng).then(
                (response) => {
                  let currently_address = response.results[0].address_components;
                  console.log(currently_address.filter(comp=> comp.types[0] == 'street_number')[0].short_name)
                  let currently = currently_address.filter(comp=> comp.types[0] == 'street_number')[0].short_name;
                  const address = response.results[0].formatted_address;
                  console.log(response);
                  setDirections({...directions, value: address, showAddress:address,
                    coords, 
                    address_components: response.results[0].address_components, street: response.results[0].address_components[1].long_name, 
                    number: currently })
                },
                (error) => {
                  console.error(error);
                }
              );
            }}
              mapContainerStyle={containerStyle}
              /* defaultValue={center} */
              center={center}
              onLoad={onLoad}
              onUnmount={onUnmount}
              zoom={16}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false
                            }}
            >
             <div className='contentInAdress'>
        <AutoCompleteComponent setLocations={setDirections} setCenter={setCenter}/>
     
            <button className='btnActualLocation' onClick={moveStart}>
            <img src={Location} className='action' alt='actual' />

            </button>
        </div> 
               <Marker position={center} />
               <div className='contentDataLocation'>
        <div className='contentAdress'>
            {directions.showAddress == '' ? directions.message: directions.value}
        </div>
        <div className='contentDataComplete'>
          <div className='contentData'>
          <div className='titleData'>
              C
              </div>
            <input type='text' className='inputData' name="street" value={directions.street} onChange={dataChange} />
          </div>
          <div className='contentData'>
            <div className='titleData'>
              #
              </div>
            <input type='text' className='inputData' name="number" value={directions.number} onChange={dataChange} />
          </div>  
          </div>
          </div>
            </GoogleMap>)
          }
        </div>

        
        </div>
       
        
        
    </div>  
  )
}

export default StepTwoComponent




import React, { useEffect, useState } from 'react'
import  PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

const AutoCompleteComponent = ({setLocations, setCenter}) => {
    const [directions, setDirections] = useState({value: '', showAddress: '', message: 'Selecciona la direccion aproximada al lugar, y modificasu numero si es necesario.', coords: {lat: null, lng: null}, address_components: [], street: ''})
    useEffect(() => {
      setLocations(directions)
      
    }, [directions])
    
   
    const handleChange= (data)=> {
        //console.log(data)
        setDirections({...directions, value: data})
    }
const handleSelect =  async (address) => {
   // console.log(address)
    const results = await geocodeByAddress(address);
    const ll = await getLatLng(results[0]);
    setCenter(ll);
    setDirections({...directions, value: address, showAddress: address, coords: ll, address_components: results[0].address_components, street: results[0].address_components[0].long_name})
      /* .then(results => getLatLng(results[0]))
      .then(latLng => console.log('Success', la11tLng))
      .catch(error => console.error('Error', error)); */
  };
  return (
    <PlacesAutocomplete 
    value={directions.value}
    onChange={handleChange} 
    onSelect={handleSelect}
    debounce={1500}
    searchOptions={{ 
        location: new window.google.maps.LatLng(25.7294336,-100.1816064),
        radius: 20000,
        types: [],
        offset: 3
      }}
    
  >
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <div style={{flex:1}}>
        <input
          {...getInputProps({
            placeholder: 'Busca una direccion ...',
            className: 'location-search-input',
          })}
        />
        <div className='contentDrop'>
        <div className="autocomplete-dropdown-container">
          {/* {loading && <div>Loading...</div>} */}
          {suggestions.map((suggestion,index) => {
            const key = 'sug'+index+1;
            const className = suggestion.active
              ? 'suggestion-item--active'
              : 'suggestion-item';
            // inline style for demonstration purpose
            const style = suggestion.active
              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
              : { backgroundColor: '#ffffff', cursor: 'pointer' };
              
            return (
              <div key={key} 
                {...getSuggestionItemProps(suggestion, {
                  className,
                  style,
                  
                })}
              >
                <span>{suggestion.description}</span>
              </div>
            );
          })}
        </div>
        </div>
        
      </div>
    )}
  </PlacesAutocomplete>
  )
}

export default AutoCompleteComponent
import React from 'react'
import './ContentRead.css'
const TyC = () => {
  return (
    <div>
        <div className='titleRead'>
            Terminos y condiciones
        </div>
        <div className='contentRead'>Al acceder y utilizar este servicio, usted acepta y accede a estar obligado por los términos y disposiciones de este acuerdo. Asimismo, al utilizar estos servicios particulares, usted estará sujeto a toda regla o guía de uso correspondiente que se haya publicado para dichos servicios. Toda participación en este servicio constituirá la aceptación de este acuerdo.
Toda la información publicada podrá ser revocada en caso de no cumplir algunas de las siguientes menciones: es contenido inapropiado de origen sexual, de origen ofensivo, si hace referencia a datos erróneos, si se presenta una queja por el dueño de algún establecimiento involucrado o en caso de que el administrador de www.expiweb.com así lo requiera.
Las cuentas podrás ser deshabilitadas en caso de que el usuario ingrese datos erróneos en mas de 5 ocasiones o si presenta algún dato con contenido de origen sexual u ofensivo.
Este documento se puede actualizar sin previo aviso y es obligación de usuario leerlo y aceptar para seguir haciendo uso de la plataforma.
www.expiweb.com le brinda la plataforma abierta al publico con ciertas limitantes como los son modificaciones en la plataforma, no se requiere una edad mínima para utilizarla siempre y cuando los menores de edad tengan el permiso de sus padres, de ser menor de edad asegúrate que lean estas condiciones también.
Esta plataforma gana dinero con anuncios así que veras muchos de ellos a lo largo de su uso.
Esta plataforma se estará actualizando constantemente
</div>
       
    </div>
  )
}

export default TyC
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import PopUpAlert from '../PopUpAlert/PopUpAlert'
import { ImgHelp } from '../StepsNP/StepOneComponentStyled'
import './SetLocation.css'
const SetLocationCurrentPost = ({locations, handleNewLocation}) => {
    const navigate = useNavigate();

    const initialAlert = {state: false, data: '', message1: '', message2: '', acept: ()=>{}, cancel: ()=>{}};
    const [popUpAlerta, setPopUpAlerta] = useState(initialAlert)
const cancel = ()=>{
    console.log('cancelando')
    console.log(initialAlert)
    setPopUpAlerta(initialAlert)

};
const acept = (id)=>{
    console.log('aceptar')
    console.log(id)
    navigate('/post/'+id)

    setPopUpAlerta(initialAlert)

};
const messagePost = (id) => {
    
    setPopUpAlerta({state: true, data: id, message1: 'Esta ubicacion cuenta con una publicacion', message2: '¿Quieres ir ahora?', 
  acept: ()=>{
    acept(id)

  }, 
  cancel: ()=>{
cancel()
  }})

}
useEffect(() => {
 console.log(popUpAlerta)
}, [popUpAlerta])

  return (
    <div  className='bodyNP'>
        {
     popUpAlerta.state && 
     <PopUpAlert popUpAlerta={popUpAlerta} />
  }
          <div className='sectionHelp'>

<div className='commentNP'>
        Hablas de alguno de estos?
</div>
<div className="containerHelps">
 <div className='newLocation' onClick={handleNewLocation}>
    Nueva ubicación
 </div>
  {locations.map(({_id, address, images}) => 
  <div key={_id} className="help" onClick={()=> messagePost(_id)} > 
  
    <ImgHelp image={images.length > 0 ? images[0]: 'https://foundit.s3.amazonaws.com/documents/notImage.png'} />

    
    <div className="dataHelp">
      <div className='nameHelp'>
        
      {address[3]?.short_name}
      </div>
      <div className='descriptionHelp'>
        {`${address[1]?.short_name} ${address[0]?.short_name} ${address[2]?.short_name} ${address[3]?.short_name} ${address[4]?.short_name} ${address[5]?.short_name} ${address[6]?.short_name}` }
      </div>
      </div> 
  </div>)}
</div>
</div>
    </div>
  )
}

export default SetLocationCurrentPost
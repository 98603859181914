import React, { useState } from 'react'
import close from './../../assets/close.svg';
import WebCamComponent from '../WebCamComponent/WebCamComponent';
import { compressImage } from '../../utils/images';

const PictureOptionsComponent = ({setPicture, imgs, setImgs}) => {
    const [webCam, setWebCam] = useState(false)
    const [preFiles, setPreFiles] = useState([])

    const openWebcam = ()=> {
        //setPicture(false)
        setWebCam(true)
    }
    const selectImage = ()=> {
        document.getElementById('inputFile').click()
      }
      const setFiles = async (documents) => {
        // const blob = new Blob([documents.target.files[0]], {type: 'image/jpg'});
         const files = documents.target.files;
         
            setPreFiles([...files /* ...imgs, {data: await blobToBase64(files[i]), url: ''} */])
            setImgs([...imgs, {data: await blobToBase64(files[0]), url: ''}])
       }
       const blobToBase64 = blob => {
        return new Promise(async (resolve, _) => {
          const newBlob = await compressImage(blob);

          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(newBlob);

        });
      }
      
  return ( !webCam ?
    <div className='contentPictureOptions'>
                        <input type='file' id="inputFile" onChange={setFiles} style={{display: 'none'}}/>

                <div className='contentOptionsComments'>
                    <div className='headerOptionsPictures'>
                    <div className='closeContentComment' onClick={()=> setPicture(false)}>
                <img src={close} className='closeAction' alt="close" />
        </div>
                        Selecciona una opcion</div>
                    <div className='optionPicture' onClick={openWebcam}>Camara</div>
                    <div className='optionPicture' onClick={selectImage}>Galeria</div>
                </div>
            </div> : 
            <WebCamComponent setBack={setWebCam} pictures={imgs} setPictures={setImgs} />
        
  )
}

export default PictureOptionsComponent
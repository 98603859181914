import React from 'react'
import './StepOneComponent.css'
import { ImgHelp } from './StepOneComponentStyled'

const StepOneComponent = ({form, setForm, posts, selected, setSelected}) => {
  const dataChange = (data)=> {
    console.log(data.target.value)
    setForm({...form, name: data.target.value})
  

  }
  return (
    <div className='bodyNP'>
            
        <div className='contentInName'>
            <input type='text' className='inName'  value={form.name} onChange={dataChange} ></input>
        </div>
        <div className='sectionHelp'>
{
  posts.length > 0 && <div className='commentNP'>
  Hablas de alguno de estos?
</div>
}
        
        <div className="containerHelps">
         
          {posts.map(post => 
          <div key={post._id} className={ selected.id == post._id? 'activeHelp' : "help"} onClick={() => selected.id == post._id? setSelected({name:'', id: ''}): setSelected({name: post.name, id: post._id})}> 
            <ImgHelp image={post.images.length > 0 ? post.images[0]: 'https://foundit.s3.amazonaws.com/documents/notImage.png'} />

            
            <div className="dataHelp">
              <div className='nameHelp'>
                
              {post.name}
              </div>
              <div className='descriptionHelp'>
                {post.description ? post.description.slice(0,80)+'...': 'Sin descripcion' }
              </div>
              </div> 
          </div>)}
        </div>
        </div>
        </div>
  )
}

export default StepOneComponent
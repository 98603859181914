import './App.css';
import {ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from,   ApolloLink,  Observable} from '@apollo/client';
import { onError} from '@apollo/client/link/error';
import { AuthContext } from './context/AuthContext';
import { useEffect, useState } from 'react';
import AppRouter from './routers/PrincipalRouter';
import Geocode from "react-geocode";
import PopUpRange from './components/PopUpRange/PopUpRange';
import { SnackBar } from './components/SnackBar/SnackBar';
import { environment } from './environment/environment';

const errorLink = onError(({graphqlErrors, networkError})=>{
  if(graphqlErrors) {
    graphqlErrors.map(({message, location, path})=>{
      alert(`graphql error ${message}`)
    })
  }
});
console.log('conected')
console.log(environment.urlApi)
const link = from([
  errorLink,
  new HttpLink({uri: 'https://api.expiweb.com/graphql'})
]);
const requestLink = new ApolloLink((operation, forward) =>
  new Observable((observer) => {
    let handle;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);
const client = new ApolloClient({
  cache: new InMemoryCache,
  link: ApolloLink.from([requestLink, link])
})
const request = async (operation) => {
  const token = await localStorage.getItem('token');
  operation.setContext({
    headers: {
      authorization: token ? `${token}` : ''
    }
  });
};
function App() {
  const [searching, setSearching] = useState({value: '', type: 'locations'});
  const [snackBar, setSnackBar] = useState({state: false, message: '', type:''});
  const [Login, setLogin] = useState(false);
  const [recomend, setRecomend] = useState(false);
  const [range, setRange] = useState(30);
  const [showRange, setShowRange] = useState({state: false});

  const [location, setLocation] = useState({coords: {lat: 25.7294336, lng:-100.1816064}});

  const [Menus, setMenus] = useState(false)
  const resetSnack = ()=> {
    setSnackBar({state: false, message: '', type:''})
  }
  useEffect(() => {
    if(snackBar.state) {
      setTimeout(()=> {
        resetSnack();
      }, 3500)
    }
  },[snackBar])
  useEffect(() => {
    getLocalStorage();
    getLocation();
  },[])
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
     //console.log(position.coords)
      getAddress(position.coords.latitude, position.coords.longitude);
      //setUser({...user, location: {coords: {lat: position.coords.latitude , lng: position.coords.longitude}}})
    }, (e) => {
      console.log('ALERTA: no nos conceden el acceso a gps, tenemos que alertar')

      getAddress(25.755648, -100.171776);


    })
  }
  const handleChangeInput = (input)=>setShowRange((data)=>
     {
      //console.log('ahora que')
      //console.log(data)
       return ({...data, data: input.target.value})
      }
       )
  const handleShowRange = ()=> {
  
    setShowRange({
      state: true,
      message1: 'Selecciona el rango de busqueda',
      message2: '',
      popUpEdit: handleChangeInput,
      data: range,
      cancel:()=>setShowRange({state:false}),
      acept:handleSetRange
    })
  }
  const handleSetRange = (NewRange)=>{
   // console.log('este reango')
    //console.log(NewRange.data)
    setRange(NewRange.data)
    setShowRange({state:false}) 
  }
  const getAddress = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const locality = response.results[0].address_components.filter(component => component.types.includes('locality'));
        const location = {showAddress:address,
          coords: {lat ,lng},
          simpleAddress: {
            locality: locality.length > 0 ? locality[0].short_name:''
          },
          address_components: response.results[0].address_components, 
          street: response.results[0].address_components[1].long_name};
          localStorage.setItem('location', JSON.stringify(location));
        //setUser({...user, location });
        setLocation(location);
      },
      (error) => {
        console.error(error);
      }
    )
  }
  const getLocalStorage = async ()=>{
    let us = await JSON.parse(localStorage.getItem('user'));
    let token = await localStorage.getItem('token');
   /*  console.log(us);
    console.log(token); */
    if(us && token) {
      
      setUser({...user, token, user: us, logIn: true, logInF: getLocalStorage})
    }
  }
  const initialState = {logIn: false, user: {}, token: null, logInF: getLocalStorage};
  const [user, setUser] = useState(initialState)
 
 
  const setAddress = (body) => {
/*    console.log(body)
 */       
       const address = body.showAddress;
        const locality =body.address_components.filter(component => component.types.includes('locality'));
        const location = {showAddress:address,
          coords: body.coords,
          simpleAddress: {
            locality: locality.length > 0 ? locality[0].short_name:''
          },
          address_components: body.address_components, 
          street: body.address_components[1].long_name};
          localStorage.setItem('location', JSON.stringify(location));
        setLocation(location); 
      
      
    
  }
  const cleanLocalStorage = async ()=>{
    await localStorage.removeItem('user');
    await localStorage.removeItem('token');
    
      setUser(initialState)
    
  }
  return (
    
    <ApolloProvider client={client}> 
    <AuthContext.Provider value={{...user, location, setAddress, getAddress, range, setRange,handleShowRange,searching, setSearching, resetSnack,setSnackBar}}>
    {
    showRange.state && 
      <PopUpRange popUpEdit={showRange} />
    
  }
    <AppRouter cleanLocalStorage={cleanLocalStorage} setMenus={setMenus} setLogin={setLogin} 
      setRecomend = {setRecomend} recomend = {recomend} Login = {Login} Menus={Menus} />
      {
        snackBar.state && <SnackBar snackBar={snackBar}  resetSnack={resetSnack} />
      }
    </AuthContext.Provider>
    </ApolloProvider>
  );
}

export default App;

import React from 'react'

const PopUpEdit = ({popUpEdit, setPopUpEdit, acept}) => {
  return (
    <div className='popUpAlerta'>
        <div className='bodyAlert'>
          <div className="titleAlert">{popUpEdit.message1}</div>
          <div className="titleAlert">{popUpEdit.message2}</div>
          <input className='inPopEdit'
          autoComplete='off' name='general' placeholder={popUpEdit.placeholder} value={popUpEdit.data} onChange={(data)=>{
            if(popUpEdit.type == 'number') {
              const result = data.target.value.replace(/\D/g, '');

              setPopUpEdit((pop)=>({...pop, data: result}))

            } else {
              setPopUpEdit((pop)=>({...pop, data: data.target.value}))
            }
          }}/>
          <div className='sectionButtonsAlert'>
          <button className='buttonAlert' onClick={popUpEdit.cancel}>Cancelar</button>
          <button className='buttonAlert' onClick={() => popUpEdit.acept(popUpEdit.data)}>Aceptar</button>
        </div>
        </div>
        
    </div>
  )
}

export default PopUpEdit
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import App from "../App";
import LoginNewUser from "../components/modal/LoginNewUser";
import { PostImage } from "../components/PostComponent/PostStyledComponent";
import PostDetailsComponent from "../components/PostDetailsComponent/PostDetailsComponent";
import LoginScreen from "../components/screens/LoginScreen/LoginScreen";
import PrincipalScreen from "../components/screens/PrincipalScreen";
import Privacy from "../components/screens/TyC/Privacy";
import RemoveData from "../components/screens/TyC/RemoveData";
import TyC from "../components/screens/TyC/TyC";

export default function AppRouter({cleanLocalStorage,setMenus, setLogin,setRecomend, recomend, Login, Menus}) {
  return (
    <Router>
      <div>
       {/*  <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          
          <Route path="/removeData" element={<RemoveData />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tyc" element={<TyC />} />
          <Route path="/login" element={<LoginNewUser />} />
          <Route path="/post/:id" element={<PostDetailsComponent />} />
          <Route path="/" element={<PrincipalScreen cleanLocalStorage={cleanLocalStorage} 
          setMenus = {setMenus} setLogin ={setLogin} setRecomend = {setRecomend} recomend = {recomend} Login ={Login} Menus = {Menus}/>} />
           
        </Routes>
      </div>
    </Router>
  );
}

function Home() {
  return <App />;
}




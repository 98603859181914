import styled from 'styled-components'

const ImageCube = styled.div`
width: 28vw;
height: 28vw;
border-radius: .7rem;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
margin-bottom: 1rem;
justify-content: center;
align-items: center;
margin-left: 4vw;
margin-top: 4vw;

background-image: url(${props => props.image || 'https://foundit.s3.amazonaws.com/documents/notImage.png'});
`;


export { ImageCube};
import React, { useState, useEffect } from 'react'
import './WebCamComponent.css';
import Webcam from "react-webcam";
import changeCameraImg from './../../assets/flip_camera_android.svg';
import Close from './../../assets/closew.svg';
import Check from './../../assets/check.svg';
import useScreenSize from '../../hooks/UseScreenSize';


const WebCamComponent = ({setPictures, pictures, setBack}) => {
  const {width, height} = useScreenSize();
    const [cameraType, setCameraType] = useState('user')
    const [img, setImg] = useState('')
    useEffect(() => {
      return () => {
        setImg('')
    }
    }, [])
    
    const changeCamera = ()=> {
      console.log('change')
        if(cameraType == 'user') {
            setCameraType('environment')
        } else {
            
            setCameraType('user')
        }
    }
    const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      //console.log(imageSrc)
      setImg(imageSrc);
     // setPictures([...pictures, imageSrc])
    },
    [webcamRef]
  );
  const resetImg = ()=> {
    setImg('')
  }
  const acceptImg = ()=> {
    
    setPictures([...pictures,{data:img, url: ''}])
    setBack(false)
  }
  return (
    <div className='backgroundWebcam'>
      <div className='sectionHeadButtons'>
      <div className='buttonCloseCamera' onClick={()=>setBack(false)}>
                        <img src={Close} className='imgCamera' alt="close" />
        </div>
        <div className='buttonChangeCamera' onClick={changeCamera}>
                        <img src={changeCameraImg} className='imgCamera' alt="close" />
        </div>
        

      </div>


        {
            img == '' ? (<div className='sectionControlsCamera' >
            <div className='buttonRedCamera' onClick={capture}></div>
        </div>) : (

<div className='sectionControlsCamera' >
    <div className='btnCamera' onClick={resetImg}>Declinar
    <img src={Close} className='actionCamera' alt="close" />

    </div>
    <div className='btnCamera' onClick={acceptImg}>Aceptar
    <img src={Check} className='actionCamera' alt="close" />
    </div>
</div>
        )
        }
        
        {
            img == '' ? (
            <Webcam className='webcamVideo'
            height={height}
            width={width}
              mirrored = {cameraType == 'user'}
             forceScreenshotSourceSize="true"
            ref={webcamRef}
            screenshotQuality={0.6}
            videoConstraints={{
             
              facingMode: {exact: cameraType}
            }}
            style = {{ }}/>
            ) :
            <div className='contentPictureT'>
              <img src={img} className='webcamVideoCapture' /> 

            </div>
        }
        
    </div>
  )
}

export default WebCamComponent
import styled from 'styled-components';

const ValueRagne = styled.div.attrs(props => ({
    // we can define static props
    type: "text",
    color: props => props.value > 75 ? '#25B54D':
    props.value > 50 ? '#CBCE24' : 
    props.value > 25 ? '#E9A906': '#F20202' ,
                
    // or we can define dynamic ones
    value: props.value * 20 || 10
  }))`
width:${props => props.value || ''}%;
height: 2rem;
background-color: ${props => props.color || ''};
border-radius: 1rem;
margin-left: 1rem;

`;
const ImgIcon = styled.img.attrs(props => ({
    value: props.value * 20 || 10,

    filter: props => props.value > 75 ? 'invert(54%) sepia(72%) saturate(536%) hue-rotate(84deg) brightness(92%) contrast(82%)':
    props.value > 50 ? 'invert(75%) sepia(89%) saturate(431%) hue-rotate(9deg) brightness(96%) contrast(84%)' : 
    props.value > 25 ? 'invert(58%) sepia(96%) saturate(816%) hue-rotate(6deg) brightness(101%) contrast(95%)': 
    'invert(7%) sepia(99%) saturate(7272%) hue-rotate(2deg) brightness(124%) contrast(98%)' 
                
    // or we can define dynamic ones
  }))`
  filter: ${props =>props.filter};
width: 3rem;
height: 3rem;

`;

export { ValueRagne, ImgIcon};

import { gql} from '@apollo/client';
const GET_MESSAGES = gql`
  mutation getComments($input: filtersMessage) {
        getComments(input: $input) {
          message {
            user_id{
              profileUrl
              name
              _id

            }
            _id
            message
            images
            at
            score
            quantityLikes
          }
          state
                    }
          
    
        }
      
`;
const addMessageQuery = gql`
mutation addMessage($input: addMessageInput) {
    addMessage(input: $input) {
        user_id {
            profileUrl
            name
            _id
        }
        message
        images
        at
        score
    }
}
`;
const addPostQuery = gql`
  mutation addPost($input: addPostInput) {
    addPost(input: $input) {
        message
    }
  }
`;
const getPostsQuery= gql`
  mutation getLocations($input: filtersInput) {
        getLocations(input: $input) {
          locations {
            _id,
            region {
              latitude, longitude
            },
            phone,
            score,
            images,
            at,
            address {
              short_name,
              types
            },
            post_id {
              name
              type
              link
              description
              
            },
            categories,
            topics,
            schedule {
              _id
              open
              close
            },
            featured {
              name
              icon
              quantity
              description
              value
            },
           description,
            likeMe,
            ratings,
            scores {
              _id {
                name
                icon
              }
              prom
            }


                    }
          
    
        }
      }
`;
const getImagesQuery= gql`
  mutation getImages($input: filtersImages) {
        getImages(input: $input) {
            _id,
            images,
            totalImages

        }
      }
`;
const updateLocationQuery = gql`
  mutation updateLocations($input: updateLocationsInput) {
    updateLocations(input: $input) {
        message
    }
  }
`;
const getPostHelpQuery = gql`
  mutation getPostRegex($input: filtersPostRegex) {
    getPostRegex(input: $input) {
       post {
        name
        description
        _id
        images
       }

    }
  }
`;
const getLocationsHelpQuery = gql`
  mutation getLocationsRegex($input: filtersInputRegex) {
    getLocationsRegex(input: $input) {
       locations {
        region {
          latitude, longitude
        },
        images,
        address {
          short_name,
          types
        },
        _id,
        score
       }

    }
  }
`;
const updateLocationGeneralQuery = gql`
  mutation upLocation($input: updateLocationInput) {
    upLocation(input: $input) {
        message
    }
  }
`;
const getCategoriesQuery = gql`
  query getCategory {
    getCategory {
      categories {
        _id
        description
        key
        url
        subCategories
        icon
      }
    }
  }
`;

const getHelpSearch = gql`
  mutation getHelpSearch($input: helpSearchInput) {
    getHelpSearch(input: $input) {
      categories {
        _id
        key
      }
      locations {
        _id
        name
        post_id {
          name
          type
          link
          description
          
        },
       
                }
      

    }
  }
`;
export {addPostQuery, getPostsQuery, addMessageQuery, GET_MESSAGES, getImagesQuery, updateLocationQuery, getPostHelpQuery, getLocationsHelpQuery, updateLocationGeneralQuery, getCategoriesQuery, getHelpSearch}
import React, { useEffect, useState } from 'react'
import { ImageToSend } from './CommentsStyledComponent'
import closew from './../../assets/closew.svg';
import { useQuery, useMutation } from '@apollo/client';
import {getPreSignedUrlQ} from '../queries/Images';
const ImageToSendComponent = ({img, index, removeImage, id, setImgs, imgs}) => {
    const [loadingAll, setLoadingAll] = useState(true)
    console.log('data de url'+ id)
    
    const [urlPresigned, data] = useMutation(getPreSignedUrlQ);
  /*   console.log(img)
    console.log(imgs)
    console.log(id)
    console.log(index) */
      useEffect(() => {
        
          urlPresigned( {
            variables: { input: {_id: id } }
          }).then(async (data)=>{
            console.log(data);
            if (data.data){
                /* console.log('data de url')
                console.log(JSON.stringify(data.data.getUrlPost.url)) */
               upFile(data.data.getUrlPost.url)
              
            }
            
          }).catch((err )=>{
            
            console.log(err);
          })
        return () => {
            setImgs([]);
        }
      }, [])
      
     
    const upFile = async(urlPre)=> {
        console.log('upFile');
        const blob = await b64ToBlob();
        //console.log(data.data.getUrlPost.url);
        const status = await fetch(urlPre, {
            method: "PUT",
            body: blob,
        });
        setLoadingAll(false);
        console.log(status.url)
        let url = status.url.split('?')[0];
        img.url = url;
        setImgs(imgs.map((imgn, indexs)=> {
            if(indexs == index) imgn.url = url
            return imgn
        }))
    }
    const b64ToBlob = async ()=> {
        //console.log(img)
        var byteCharacters = atob(img.data.replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, ''));
        //const byteCharacters = window.atob(encodeURIComponent( img.replace(/\+/g, " ")));
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'image/jpg'});
        return blob
    }
    useEffect(() => {
      
    
      return () => {
        
      }
    }, [])
   const getPreSignedUrl = ()=> {
   }
   /*  console.log(img) */
  return (
<ImageToSend image={img.data} >
    {
        !loadingAll && (
            <div className='buttonCloseToSend' onClick={()=>removeImage(index)}>
                        <img src={closew} className='closeAction' 
         alt="close" />
                        </div>
        )
    }
    {
        loadingAll && (<div className='backLoadingImg' >
        <div className="loader"></div>
        </div>)
    }
                        
                    </ImageToSend>  )
}

export default ImageToSendComponent
import React from 'react'
import imageNP from './../../assets/image 32.svg';


const FinishStepComponent = ({setRecomend, goToPost}) => {
  return (
    <div className='bodyNP'>
        <div className='sectionThanks'>
          <div className='thanksNP'>¡Gracias!</div>
          
        </div>
        <div className='sectionMessageNP'>
          <div className='messageNP'>Ir a la publicacion?</div>
          <div className='sectionButtonsNP'>
              <button className='btnl btnNPF' onClick={()=> setRecomend(false)}>Inicio</button>
              <button className='btnr btnNPF' onClick={goToPost}>Post</button>
          </div>
          
        </div>
        <div className='sectionImgeNP'>
        <img src={imageNP} className='imgNP'
     alt="thanks" />
          
          
        </div>
        
    </div> 
  )
}

export default FinishStepComponent
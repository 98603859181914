import React, { useEffect, useState } from 'react'
import { PostImageDetails } from '../PostComponent/PostStyledComponent'
import { useMutation, useQuery } from '@apollo/client';
import { getImagesQuery, getPostsQuery, GET_MESSAGES, updateLocationQuery,updateLocationGeneralQuery} from '../queries/Post';
import { useNavigate, useParams } from 'react-router-dom';
import Call from './../../assets/call.svg';
import Share from './../../assets/share.svg';
import Navigation from './../../assets/navigation.svg';
import Like from './../../assets/thumb_up_alt.svg';
import LikeFill from './../../assets/thumb_up_alt_fill.svg';

import Money from './../../assets/money.svg';
import Deck from './../../assets/deck.svg';
import Chat from './../../assets/chat.svg';
import Edit from './../../assets/edit.svg';
import EditA from './../../assets/editw.svg';
import Watch from './../../assets/watch_later.svg';
import Notification from './../../assets/notifications_active.svg';
import Loc from './../../assets/place.svg';
import './PostDetailsComponent.css';
import { GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';
import { ImgIcon, ValueRagne } from './PostDetailsStyledComponents';
import { helpers } from '../helpers/helpers';
import close from './../../assets/close.svg';
import CommentsComponent from '../CommentsComponent/CommentsComponent';
import CommentComponent from '../commentComponent/CommentComponent';
import ImagesComponent from '../imagesComponent/ImagesComponent';
import ShareComponent from '../ShareComponent/ShareComponent';
import PopUpAlert from '../PopUpAlert/PopUpAlert';
import {helpersPost} from '../helpers/postHelper';
import PopUpEdit from '../PopUpEdit/PopUpEdit';
import TopicsComponent from '../TopicsComponent/TopicsComponent';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';
import StepTwoComponent from '../StepsNP/StepTwoComponent';

const PostDetailsComponent = () => {
    const data = useContext(AuthContext);

    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [shareComponent, setShareComponent] = useState({state: false, id: ''})
    const [popUpAlerta, setPopUpAlerta] = useState({state: false, data: '', message1: '', message2: '', aceptar: ()=>{}, cancelar: ()=>{}})
    const initialEdit = {state: false, placeholder: '', data: '', message1: '', message2: '', aceptar: ()=>{}, cancelar: ()=>{}};
    const [form, setForm] = useState(true)
    const [showLocation, setShowLocation] = useState(false)
    const [popUpEdit, setPopUpEdit] = useState(initialEdit)
    const [popUpTopics, setPopUpTopics] = useState(initialEdit)

    const containerStyle = {
        width: '100%',
        height: '100%'
      };
      const [center, setCenter] = useState({lat: 25.755648, lng: -100.1816064})
      const [comments, setComments] = useState(false)
      const [images, setImages] = useState(false)
      const [commentsDetails, setCommentsDetails] = useState([])
      const [imagesData, setImagesData] = useState({images: [], totalImages: 0})

      const addComment = () => {
          setComments({state: true, id: post.post_id._id, name: post.post_id.name})
      }
      const {id} = useParams();

    useEffect(() => {
      upLocMut();
      GetMessagePag({
        variables: { input: {_id: id, numberPage: page, size: 5 } }
      }).then(async (data)=>{
        console.log(data);
        if (data.data ){
          console.log(data.data.getComments);
          setCommentsDetails(data.data.getComments.message)
          //setComments([...comments, ...data.data.getComments.message]);
          
        }
        
      }).catch((err )=>{
        
        console.log(err);
      })

      GetImagesPag({
        variables: { input: {id: id, skip: 0,  limit: 30 } }
      }).then(async (data)=>{
        console.log(data);
        if (data.data ){
          console.log(data.data);
          //setCommentsDetails(data.data.getComments.message)
          //setComments([...comments, ...data.data.getComments.message]);
          setImagesData(data.data.getImages) 
        }
        
      }).catch((err )=>{
        
        console.log(err);
      })
    }, [])
    
    useEffect(()=>{
      console.log(form)
    },[form])
    console.log('dentro del id');
    console.log(id);
    const [post, setPost] = useState({loading: true})
  const [updateLocation , addUpLoc] = useMutation(updateLocationQuery);
  const [postMut , addPostMut] = useMutation(getPostsQuery);
  const [GetImagesPag, dataImages] = useMutation(getImagesQuery)
  const [GetMessagePag, { loading: loadingUpdate, error:errorUp, data: dataUp}] = useMutation(GET_MESSAGES)
  const [upLocQ , addUpGenLoc] = useMutation(updateLocationGeneralQuery);

  const [day, setDay] = useState(  new Date().getDay())
  const aceptEdit = (data)=> {
    console.log(data)
    upLocQ({
      variables: { input: {_id: id, update: JSON.stringify({description: data
      })} }
    }).then(async (data)=>{
      console.log(data);
      if (data.data ){
        let obj = JSON.parse(data.data.upLocation.message)


       //setComment(data.data.getComments.message[0]);
       setPost({...post, description: obj.description })
       setPopUpEdit(initialEdit)
      }
      
    }).catch((err )=>{
      
      console.log(err);
    })
  }
  const aceptEditPhone = (data)=> {
    console.log(data)
    upLocQ({
      variables: { input: {_id: id, update: JSON.stringify({phone: data
      })} }
    }).then(async (data)=>{
      console.log(data);
      if (data.data ){
        let obj = JSON.parse(data.data.upLocation.message)

       //setComment(data.data.getComments.message[0]);
       setPost({...post, phone:obj.phone })
       setPopUpEdit(initialEdit)
      }
      
    }).catch((err )=>{
      
      console.log(err);
    })
  }
const handleEditDescription = ()=> {
  setPopUpEdit({state: true, placeholder: 'descripcion', 
  data: '', 
  message1: 'Agrega una descripcion', message2: '', 
  acept: aceptEdit, 
  cancel: ()=>setPopUpEdit(initialEdit)})
}
const handleEditPhone = ()=> {
  setPopUpEdit({state: true, placeholder: 'telefono', 
  data: '', 
  message1: 'Agrega un telefono', message2: '', 
  type: 'number',
  acept: aceptEditPhone, 
  cancel: ()=>setPopUpEdit(initialEdit)})
}
const handleEditTopics = (topics)=>{
  console.log(topics)
  aceptEditTopics(topics.map(topic => topic.key));
}
const aceptEditTopics = (data)=> {
  console.log(data)
  upLocQ({
    variables: { input: {_id: id, update: JSON.stringify({topics: data
    })} }
  }).then(async (data)=>{
    console.log(data);
    if (data.data ){
      let obj = JSON.parse(data.data.upLocation.message)

     //setComment(data.data.getComments.message[0]);
     setPost({...post, topics:obj.topics })
     setPopUpTopics(initialEdit)
    }
    
  }).catch((err )=>{
    
    console.log(err);
  })
}
const setData =(data)=> {
  console.log('data de iamges')
  console.log(imagesData)
  setImagesData({images: [imagesData.images, ...data.addMessage.images], totalImages: imagesData.totalImages + data.addMessage.images.length})
  setCommentsDetails([data.addMessage, ...commentsDetails])


}
const handleTopics = ()=> {
  setPopUpTopics({state: true,
  data: [...post.topics], 
  message1: '', message2: '', 
  acept: handleEditTopics, 
  cancel: ()=>setPopUpTopics(initialEdit)})
}
    const upLocMut = (val, match= {"$match":{}}) => {
        let qty = 10;
        let limit = 1;
        let skip = 0;
        let filter = JSON.stringify([{}]);

        /* ,{"$match": {${val}}} */
        postMut({
          variables: { input: { filter, 
          match: JSON.stringify({"$match": match}),
             limit, skip, id } },
        }).then((resp)=>{
          //console.log(resp.data.getLocations.locations[0].region)
          if(resp.data.getLocations.locations.length > 0 ) {
            console.log('expi');
            if(resp.data.getLocations.locations.length > 0) {

                setPost({...resp.data.getLocations.locations[0], loading: false})
                setCenter({lat:resp.data.getLocations.locations[0].region.latitude, 
                    lng:resp.data.getLocations.locations[0].region.longitude})
            } else {

            }
            
          /*   setPosts([...posts,...resp.data.getLocations.locations])
            setnumberPage(numberPage+1) */
          } else {
    
            /* if(resp.data.getLocations.locations.length < 10 ) {
              
              getPlacesGoogle();
            } */
          }
         
                 //getPlacesGoogle();
    
                /*  let com = [resp.data.addMessage,...dataComments] */
                 //console.log(JSON.stringify(resp.data))
                 /* reset();
                 navigation.goBack();
                 navigation.navigate('DataPost', {_id: data.data.addPost.message, distance: 'km'}) */
             }).catch((e)=> {
                //alertAll('error al ingresar comentario');
                 console.log(e);
             })
    
       
    }
    const updateLoc = async()=> {
      if(!data.logIn) {
        setPopUpAlerta({state: true, data: 'login', message1: 'Aun no has iniciado sesion', message2: 'Quieres hacerlo ahora?', 
        acept: ()=>{
          navigate('/login')
          setPopUpAlerta({state: false, data: '', message1: '', message2: '', aceptar: ()=>{}, cancelar: ()=>{}})
    
        }, 
        cancel: ()=>{
          setPopUpAlerta({state: false, data: '', message1: '', message2: '', aceptar: ()=>{}, cancelar: ()=>{}})
    
        }})
    
       } else {
        const like = await helpersPost.likePost( post._id, setPost, updateLocation, post.likeMe);

       }
     
    }
  const sendLocations = async ()=> {
      //data.setAddress(form.directions)
          //setShowLocation(false)
          console.log('form locations')
          console.log(form.directions)
          ///coords
          //let coords: { type: 'Point', coordinates: [ form.directions.coords.lng, form.directions.coords.lat],  description: ''};
          let coords = {type: 'Point', coordinates: [form.directions.coords.lng,form.directions.coords.lat],  description: ''};
          ///region
          let region = {latitude: form.directions.coords.lat, longitude: form.directions.coords.lng  };
          //address = []
          const address = form.directions.address_components;
          aceptEditAddress(coords, region, address)

  }
  const aceptEditAddress = (coords, region, address)=> {
    console.log(data)
    upLocQ({
      variables: { input: {_id: id, update: JSON.stringify({
        address: address,
        coords,
        region
      })} }
    }).then(async (data)=>{
      console.log(data);
      if (data.data ){
        let obj = JSON.parse(data.data.upLocation.message)
        console.log(obj)
       //setComment(data.data.getComments.message[0]);
       setPost({...post, address: address,
        coords,
        region  })
        setCenter({lat:coords.latitude, 
          lng:coords.longitude})
        setShowLocation(false)
      }
      
    }).catch((err )=>{
      data.setSnackBar({state: true, message: err.message, type: 'error'})
      
      console.log(err);
    })
  }
  
  return (  !comments.state ? 
    ( shareComponent.state ? <ShareComponent id={shareComponent.id}  setShareComponent={ setShareComponent}/> : !images ? (<div>
         {
        popUpAlerta.state && 
        <PopUpAlert popUpAlerta={popUpAlerta} />
        }
        {
        popUpEdit.state && 
        <PopUpEdit popUpEdit={popUpEdit} setPopUpEdit={setPopUpEdit} />
        }
        {
          popUpTopics.state && <TopicsComponent popUpTopics={popUpTopics} context={data} />
        }
        <PostImageDetails  image={!post.loading && post.images.length > 0 ? post.images[0]: 'https://foundit.s3.amazonaws.com/documents/notImage.png'} >
        {
    showLocation && <div className='showLocationComponent'>
      <StepTwoComponent initial={data.location?.coords}  form={form}  setForm={setForm} />
      <div className='sectionButtonsUpdateLocation'>
        <button className='buttpnUpLocC' onClick={()=>setShowLocation(false)}>
          Cancelar
        </button>
        <button className='buttpnUpLocA' onClick={sendLocations}>
          Aceptar
        </button>
      </div>
    </div>
  }
    <div className='contentHeaderDetails'>
        <div className='closeContent' onClick={()=> navigate('/')}>
                <img src={close} className='closeAction' alt="close" />
        </div>
        <div className='contentCategorieDetails'>

    <div className='tagImagesDetails' onClick={()=>{setImages(true)}}> {`${imagesData?.totalImages?imagesData?.totalImages : 0} imagenes`}</div>
        </div>
        
    </div>
    <div className='dataSection'>
      <div className='mid fristData title'>
        <h1 className='titleh1'>{!post.loading && post.post_id?.name}</h1>
        </div>
      <div className='mid secondData'>
        <div className='contentStatus'>
          {
            (!post.loading && post.schedule[day].open == null) && <span className='statusPostSN'>Sin Horario</span>
          }
          
        </div>
        <div className='contentScore'>
          <span className={(!post.loading && post.score >= 1) ?'start startActive': 'start'}>&#9733;</span>  
          <span className={(!post.loading && post.score >= 2) ?'start startActive': 'start'}>&#9733;</span>  
          <span className={(!post.loading && post.score >= 3) ?'start startActive':  'start'}>&#9733;</span>  
          <span className={(!post.loading && post.score >= 4) ?'start startActive': 'start'}>&#9733;</span>  
          <span className={(!post.loading && post.score >= 5) ?'start startActive':'start'}>&#9733;</span>  
          <span className='score'>{!post.loading && post.score.toFixed(1)}</span>  
        </div>  
      </div>

    </div>
  </PostImageDetails >
  <div className='contentActionsDetails'>
          <div className='actionOne'>
          {
              post.phone != '' ?
              <a href={`tel:${post.phone}`}>
          <img src={Call} className='actions'
            alt="call" />
            </a> :
             <img src={Call} className='actions'
             alt="call" onClick={()=> setPopUpAlerta({state: true, data: post._id, 
              message2: 'Quieres entrar a detalles a registrar uno?',
               message1: 'Aun no tenemos un telefono registrado',
              acept: ()=> {
                navigate('/post/'+popUpAlerta.data)
                setPopUpAlerta({state: false, data: '', message1: '', message2: '', acept: ()=>{}, cancel: ()=>{}})
      
              },
              cancel:()=> setPopUpAlerta({state: false, data: '', message1: '', message2: '', acept: ()=>{}, cancel: ()=>{}})
            
            })}/>
            }
          
     <img src={Share} className='actions' onClick={()=>setShareComponent({state: true, id: post._id})}
     alt="share" />
     <img src={Navigation} className='actions'
     alt="navigation" onClick={()=>helpers.openMaps(center.lat, center.lng)} />
     {/* <img src={Compare} className='actions'
     alt="compare" />
     <img src={Event} className='actions'
     alt="event" /> */}
          </div>
          <div className='actionTwo'>
          <img src={Chat} className='actions'
     alt="chat" onClick={addComment} />
     {

       !post.likeMe ?
       <img src={Like} className='actions'
     alt="like" onClick={updateLoc} /> :
            <img src={LikeFill} className='actions'
            alt="like" onClick={updateLoc} /> 
          }
     
</div>
          
        </div>

        <div className='sectionDataDetails'>
            <div className='sectionsDetails'>

            <h3 className='titleDataDetails'>Descripción</h3>
            <img src={Edit} className='actionDetails'
     alt="share" onClick={handleEditDescription} />
            </div>
            
            <p className='contentDataDetails'>{post.description? post.description:'Se el primero en agregar una descripción'}</p>
        </div>

        <div className='sectionDataDetails'>
            <div className='sectionsDetails'>

            <h3 className='titleDataDetails'>Telefono</h3>
            <img src={Edit} className='actionDetails'
     alt="share" onClick={handleEditPhone} />
            </div>
            
            <p className='contentDataDetails'>{post.phone? post.phone: 'Se el primero en agregar un telefono'}</p>
        </div>
        <div className='contentMapDetails'>
            <div className='mapDetails'>
                <div className='mapAddress'>
                <img src={Loc} className='actionDetails'
     alt="share" />
        <div className='addressTextDetails'>
            {!post.loading && `${post.address[1].short_name}, ${post.address[0].short_name}, ${post.address[2].short_name}, ${post.address[3].short_name}, ${post.address[4].short_name}`}
         </div>
                <img src={EditA} className='actionDetails'
     alt="share" onClick={()=>setShowLocation(true)} />
                </div>
                {
                    center != {} && <GoogleMap
                    mapContainerStyle={containerStyle}
                    /* defaultValue={center} */
                    center={center}
                  
                    zoom={16}
                    options={{
                      zoomControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false
                                  }}
                  >
                   
                     <Marker position={center} />
                   
                  </GoogleMap>
                }
                
            </div>
        </div>
        
        <div className='sectionDataDetails'>
            <div className='sectionsDetails'>

            <h3 className='titleDataDetails'>Temas</h3>
            <img src={Edit} className='actionDetails'
     alt="share" onClick={handleTopics} />
            </div>
            
            <div className='sectionTagsDetails'>
                {
                    !post.loading && post.topics.map((topic, index)=>
                        <div className='tagDetails' key={index+'tag'}>{topic}</div>
                        )
                }
            </div>
        </div>
        <div className='sectionDataDetails'>
            <div className='sectionsDetails'>

            <h3 className='titleDataDetails'>Valoraciónes</h3>
            
            </div>
            
            <div className='sectionVauesDetails'>
              {
                !post.loading && post.scores.map((score, index)=> score.prom > 0 ?
                <div className='valuesDetail' key={index+'detail'}>
                <ImgIcon value={score.prom} src={score._id?.name == 'Instalaciones' ? Deck : 
                 score._id?.name == 'Tiempo' ? Watch :
                 score._id?.name == 'Servicio' ? Notification : Money}/>
                <div className='contentValue'>
                    <ValueRagne value={score.prom} color=""/>
                </div>
               </div>: 
                          index == 0 && <p className='contentWithOutComments'>Aun no tenemos valoraciones</p>
                           )
              }
                
              
              
               
            </div>
        </div>
        <div className='sectionDataDetails'>
            <div className='sectionsDetails'>

            <h3 className='titleDataDetails'>Comentarios</h3>
            {/* <img src={Edit} className='actionDetails'
     alt="share" /> */}
            </div >
            {
              commentsDetails.length == 0 ?
              <p className='contentWithOutComments'>No tenemos comentarios aun </p>
              :
              (
                <div className='commentComponentContent'>
                {
                commentsDetails.map(comm => <CommentComponent key={comm._id} comment={comm} />)
                }
                </div>
              )
            }
        </div>
        <div className='moreComments' onClick={addComment}>Agregar comentarios</div>
        
    </div>): (<ImagesComponent setImagesData={setImagesData} images={imagesData.images} name={post.post_id?.name} turnBack={()=>setImages(false)} />)) : <CommentsComponent namePost={post.post_id.name} id={id} setData={setData} setComments={() =>setComments({state: false, id: '', name: ''})} />
  )
}

export default PostDetailsComponent
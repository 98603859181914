import React from 'react'
import './ShareComponent.css';
import close from './../../assets/close.svg';

import {
    FacebookShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
 
    LineShareButton,
    LineIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
  } from "react-share";
const ShareComponent = ({id, setShareComponent}) => {
  return (
<div style={{position: 'absolute', top: 0, bottom: 0, backgroundColor: '#fff', right: 0, left: 0}}>
    <div style={{fontSize:20, fontWeight: 700, textAlign: 'center', padding:10}} >
    <img src={close} className='closeActionShare' onClick={()=>setShareComponent({state: false, id: ''})}
     alt="close" />
         Compartir </div>
    <WhatsappShareButton url={'https://www.expiweb.com/post/'+id} className='buttonShare' resetButtonStyle={false}>
        <WhatsappIcon size={36} round={true} />
        <span className='textButtonShare'>

        Whatsapp
        </span>
    </WhatsappShareButton>
    <FacebookShareButton url={'https://www.expiweb.com/post/'+id} className='buttonShare' resetButtonStyle={false}>
        <FacebookIcon size={36} round={true} />
        <span className='textButtonShare'>

        Facebook
        </span>
    </FacebookShareButton>
    <FacebookMessengerShareButton url={'https://www.expiweb.com/post/'+id} className='buttonShare' resetButtonStyle={false}>
        <FacebookMessengerIcon size={36} round={true} />
        <span className='textButtonShare'>

        Facebook Messenger
        </span>
    </FacebookMessengerShareButton>
    <TwitterShareButton url={'https://www.expiweb.com/post/'+id} className='buttonShare' resetButtonStyle={false}>
        <TwitterIcon size={36} round={true} />
        <span className='textButtonShare'>

        Twitter
        </span>
    </TwitterShareButton>
    <TelegramShareButton url={'https://www.expiweb.com/post/'+id} className='buttonShare' resetButtonStyle={false}>
        <TelegramIcon size={36} round={true} />
        <span className='textButtonShare'>

        Telegram
        </span>
    </ TelegramShareButton>
    <LineShareButton url={'https://www.expiweb.com/post/'+id} className='buttonShare' resetButtonStyle={false}>
        <LineIcon size={36} round={true} />
        <span className='textButtonShare'>

        Line
        </span>
    </ LineShareButton>
    <LinkedinShareButton url={'https://www.expiweb.com/post/'+id} className='buttonShare' resetButtonStyle={false}>
        <LinkedinIcon size={36} round={true} />
        <span className='textButtonShare'>

        Linkedin
        </span>
    </ LinkedinShareButton>
    
   
    </div>  )
}

export default ShareComponent
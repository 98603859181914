import React from 'react'
import './popUpRange.css'
const PopUpRange = ({popUpEdit}) => {
  return (
    <div className='showRngeComponent'>
    <div className='popUpAlerta'>
        <div className='bodyAlert'>
          <div className="titleAlert">{popUpEdit.message1}</div>
          <div className="rangeValue">{popUpEdit.data} KM</div>
          <input className='slider' type='range' min="10" max="200" step="10" id="inputRange"
           name='range'value={popUpEdit.data} onChange={popUpEdit.popUpEdit}/>
          <div className='sectionButtonsAlert'>
          <button className='buttonAlert' onClick={popUpEdit.cancel}>Cancelar</button>
          <button className='buttonAlert' onClick={()=>popUpEdit.acept(popUpEdit)}>Aceptar</button>
        </div>
        </div>
        
    </div>
    </div>
  )
}

export default PopUpRange
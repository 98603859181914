import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'
import PostComponent from '../PostComponent/PostComponent';
import './HomeScreen.css';
import LoginNewUser from '../modal/LoginNewUser';
import { AuthContext } from '../../context/AuthContext';
import { useMutation, useQuery } from '@apollo/client';
import { getCategoriesQuery, getPostsQuery} from '../queries/Post';
import ShareComponent from '../ShareComponent/ShareComponent';
import { useNavigate } from 'react-router-dom';
import PopUpAlert from '../PopUpAlert/PopUpAlert';
import CommentsComponent from '../CommentsComponent/CommentsComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faDog, faBriefcase, faUtensils, faHouse, faHeart, faClose, faHandHolding, 
  faFaceLaughBeam, faBook, faShirt, faChild} from '@fortawesome/free-solid-svg-icons'

const HomeScreen = ({setLogin, naviga, commentComponent, setComment, setNewPost}) => {
  //let filter = JSON.stringify({selectTags: {"$in": post.selectTags}, _id: {"$ne": post._id}});
  
  const { loading, error, data: dataCategories } = useQuery(getCategoriesQuery);
  const [categories, setCategories] = useState([
  ]);
  const [icons, setIcons] = useState({
    faDog, faBriefcase, faUtensils, faHouse, faHeart, faHandHolding, faFaceLaughBeam, faBook, faShirt, faChild
  })
  const [shareComponent, setShareComponent] = useState({state: false, id: ''})
  const [mounted, setMounted] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [lastSearch, setLastSeacrh] = useState('')
  const [hasMore, setHasMore] = useState(true)
  const data = useContext(AuthContext);
  const [lastAddress, setLastAddress] = useState({});

  const [popUpAlerta, setPopUpAlerta] = useState({state: false, data: '', message1: '', message2:'', acept: ()=>{}, cancel:()=>{}})
  const [numberPage, setnumberPage] = useState(1)
  const [posts, setPosts] = useState([])
  const [postsList , addPostsList] = useMutation(getPostsQuery);
  /* const { postsList, loading, error } = useQuery(getPostsQuery, {
    variables: { input: { filter, limit: 5, skip: 0 } }
  }); */
  const observer = useRef(); // (*) 
const changeLoading = ()=> {
setIsLoading(true)
}
const moreData = ()=> {
  setIsLoading(true)
  const {value, type} = data.searching
  console.log(value)
  const NewSearch = lastSearch !== value || lastAddress !== data.location.coords;
  if(NewSearch && mounted ) {
    setPosts([])
    setnumberPage(1)
    setHasMore(true)
  }
  setLastSeacrh(value);
  setLastAddress(data.location.coords);

    if(type === 'locations') {
      if(value == '') {
        //console.log('entrando a en blanco')
        const search = {"$match":{}}
        if(mounted)
        upLocMut('', search, NewSearch);
        
      } else {
        
        const search = {"$match":{name:{$regex: value, $options: 'i'}}}
        if(mounted)
        upLocMut('', search, NewSearch);
      }
    } else {
      const search = {"$match":{topics:{$regex: value, $options: 'i'}}}
      if(mounted)
      upLocMut('', search, NewSearch);
    }
  
    
}


const moreDataRef = useCallback( // (*) 
(node) => { 

  if (isLoading) return;
  if (observer.current) observer.current.disconnect();
  observer.current = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting && !isLoading && hasMore) {
      console.log(isLoading);
      changeLoading();
      setnumberPage((prev) => prev + 1);
    }
  });
  if (node) observer.current.observe(node);
},
[isLoading, hasMore] 
); 
  useEffect(() => {
    if(dataCategories) {
      setCategories(dataCategories.getCategory.categories.map(category=>({...category, state: false})))
      }
  }, [dataCategories])
  useEffect(() => {
    if (mounted) moreData();    // add conditional check
    
    
  }, [data.searching, numberPage,data.range, data.location.coords])
  useEffect(() => {
  setMounted(true)  

  return () => { setMounted(false)  }; // cleanup toggles value, if unmounted

}, [])


/* useEffect(()=>{

  setCoords(data.location.coords)
  setnumberPage(1);
  upLocMut('');
}, []) */
  
  const upLocMut = (val, match= {"$match":{}}, newSearch = false) => {
    let qty = 5;
    let limit = qty;
    let skip = (qty * (numberPage-1));
    
    
    if(posts.length > 0 && !newSearch && numberPage === 1) return

    postsList({
      variables: { input: { filter: 
        `[ { "$geoNear": {
          "near": {
              "type": "Point",
              "coordinates": [${data.location.coords.lng},${data.location.coords.lat}]
          },
          "distanceField": "distance",
          "spherical": true,
          "maxDistance": ${data.range * 1000}
      }}]`, 
      match: JSON.stringify( match ),
         limit, skip, id: ''} },
    }).then((resp)=>{
      console.log('limit '+ limit)
    console.log('skip '+ skip)
    console.log(resp.data.getLocations.locations)
      if(resp.data.getLocations.locations.length > 0 ) {
        if(resp.data.getLocations.locations.length > 0) {
          if( newSearch ) {

            setPosts([...new Set([...resp.data.getLocations.locations])])
          } else {

            setPosts([...new Set([...posts,...resp.data.getLocations.locations])])
          }
          if(resp.data.getLocations.locations < qty) {

            setHasMore(false)

          }
        }
       

      } else {
        console.log('resp.data.getLocations');
        console.log(resp.data.getLocations.locations);

        if( numberPage == 1) {
          setPosts([]);
        } else {
          setHasMore(false)

        }

      }

         }).catch((e)=> {
            //alertAll('error al ingresar comentario');
             console.log(e);
         }).finally(()=>{
          setIsLoading(false)
         })

   
}

  return ( shareComponent.state ? <ShareComponent id={shareComponent.id}  setShareComponent={ setShareComponent}/> :
  commentComponent.state ? <CommentsComponent name={commentComponent.name} id={commentComponent.id} setComments={()=>setComment({state: false, id: '', name: ''})} /> : 
    <div className='HomeBody'>
      {
        popUpAlerta.state && 
        <PopUpAlert popUpAlerta={popUpAlerta}  />
       }
      
      
          {
            data.searching.value === '' ?
        <div className='contentFiltersCategory'>
            {categories.map(category=><button className='buttonCatogory' key={category.key  } onClick={()=>data.setSearching({value: category.key, type: 'categories'})}>
              {
                category.icon !== '' &&
                <FontAwesomeIcon icon={icons[category.icon]} key={category._id} size="1x" color='#fff' style={{marginRight: '1rem'}}/>
              }
              {category.key}
              </button>)}
             
        </div> :
        <div className='contentFiltersCategory'>
          <div className='titleFilter'>

          Filtros
          </div>
          <div className='contentButtonFilter'>
          <button className='buttonCatogory' onClick={()=>{data.setSearching({value: '', type: 'locations'})
         }}>

            {data.searching.value}
             <FontAwesomeIcon icon={faClose} size="1x" color='#fff' style={{marginLeft: '1rem'}}/> 
          </button>
        </div>
          </div>
          }
                    <div className='contentBodyPosts'>
        {

          posts.length > 0 ? <div className='bodyPosts'  >
           
          {
            posts.map((post, index) =>
            
             
              posts.length == (index + 1) ?
              (<PostComponent key={post._id} postC={post}
                ref={moreDataRef}  setShareComponent={setShareComponent} setCommentComponent={setComment} setPopUpAlerta={setPopUpAlerta} />):
              (<PostComponent key={post._id} postC={post}  setShareComponent={setShareComponent} setCommentComponent={setComment} setPopUpAlerta={setPopUpAlerta} />)
            
              
              )
          }
          {
            
            hasMore ? <div className="contentPreloader">
            <div className="box" >
              <div className="container">
                <span className="circle"></span>
                <span className="circle"></span>
                <span className="circle"></span>
                <span className="circle"></span>
              </div>
            </div>
            </div> : <div className='notFoundPosts' style={{paddingTop: '6rem'}}>
          <FontAwesomeIcon icon={faMagnifyingGlass}  size="8x" color='#ccc'/>
          <p className="textNotFoundPosts">Esta busqueda no cuenta con mas contenido, ingresa algo nuevo para ayudar a los demas a encontrar mas opciones.</p>
          <div className="buttonNotFoundContent">
          <button className="buttonNotFoundAdd" onClick={setNewPost}>Agregar</button>
          </div>
      </div>

            
          }
           
      </div> : <div className='notFoundPosts'>
          <FontAwesomeIcon icon={faMagnifyingGlass}  size="8x" color='#ccc'/>
          <p className="textNotFoundPosts">No encontramos publicaciones en este rango de busqueda, agrega una para ayudar a los demas a encontrar lo que necesitan.</p>
          <div className="buttonNotFoundContent">
          <button className="buttonNotFoundAdd" onClick={setNewPost}>Agregar</button>
          </div>
      </div>
        }
        </div>

        <div className='extra'>

        </div>
        
    </div>
  )
}

export default HomeScreen

const PostRefComponent = React.forwardRef(({post, setShareComponent, setComment, setPopUpAlerta}, moreDataRef) => (
  <PostComponent key={post._id} postC={post}
              ref={moreDataRef}  setShareComponent={setShareComponent} setCommentComponent={setComment} setPopUpAlerta={setPopUpAlerta} />
));
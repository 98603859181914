import { useState, useEffect } from "react";

const useScreenSize = () => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    const handleResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      };
    window.addEventListener("resize", handleResize);
    
    return () => {
        window.removeEventListener("resize", handleResize);
      };
    
  }, []);
  return { height, width }
}

export default useScreenSize;

import { gql} from '@apollo/client';

const queryAuthAccount = gql`
  mutation authAccount($input: authAccountInput) {
    authAccount(input: $input) {
        message
        error
    }
  }
`;
const queryVerifyEmail = gql`
  mutation verifyEmail ($input: verifyEmailInput ) {
    verifyEmail (input: $input) {
      status
      preSignedEmail
      message
    }
    
  }
`;

const loginFacebook = gql`
mutation loginFacebook($input: loginFacebookInput) {
  loginFacebook (input: $input) {
    token
    name
    email
    url
    status
    }
    
  }
`;

const queryUpdateUser = gql`
mutation updateUser($input: userInput){
    updateUser(input: $input){
        
        name
        email
        img
        
    }
}
`;

const queryLogIn = gql`
mutation logIn($input: logInInput){
  logIn(input: $input){
        token
        name
        email
        profileUrl
        status
        
    }
}
`;

export {queryAuthAccount, queryVerifyEmail, queryUpdateUser, queryLogIn, loginFacebook}
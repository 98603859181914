import styled from 'styled-components'

const PostImage = styled.div`
width:100%;
height:100%;
margin-top:.5rem;
padding: 1rem 0;
position: relative;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
background-image: url(${props => props.image || 'https://foundit.s3.amazonaws.com/documents/notImage.png'});
`;
const PostImageDetails = styled.div`
width:100%;
height:100%;
padding: 1rem 0;
padding-top: 0;
position: relative;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
background-image: url(${props => props.image || 'https://foundit.s3.amazonaws.com/documents/notImage.png'});
min-height: 30vh;
`;
const BoxImage = styled.div`
width: 100%;
height: 40vh;
  
`;
const ImgUser = styled.div`
height: 4rem;
width: 4rem;
border-radius: 2rem;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
background-image: url(${props => !!props.url ? props.url: 'https://foundit.s3.amazonaws.com/documents/notImage.png'})
}
  
`;


export { PostImage, BoxImage, ImgUser, PostImageDetails};
import React, { useEffect, useState } from 'react'
import './StepTwoComponent.css'
import Location from './../../assets/location.svg';
import { useQuery } from '@apollo/client';
import { getCategoriesQuery } from '../queries/Post';

const StepThreeComponent = ({setForm, form}) => {
  const { loading, error, data } = useQuery(getCategoriesQuery);
  const [categories, setCategories] = useState([
    /* {state: false,value: 'Comida', clave: 'comida', _id: '1'},
    {state: false,value: 'Salud', clave: 'salud', _id: '2'},
    {state: false,value: 'Servicios', clave: 'servicios', _id: '3'},
    {state: false,value: 'Hoteles', clave: 'hoteles', _id: '4'} */
  ]);
  useEffect(() => {
    
    setForm({...form, topics: categories.filter(cat=>cat.state).map(cat=>cat.key)})
   
  }, [categories])
  useEffect(() => {
    
   // setForm({...form, categories: categories.filter(cat=>cat.state).map(cat=>cat.clave)})
   console.log(form.topics)
  }, [form])
  useEffect(() => {
    if(data) {
      console.log(data.getCategory.categories)
      setCategories(data.getCategory.categories.map(category=>({...category, state: false})))
    }
     
    }, [data])
  const selectTag = (id) => {
    console.log(id)
    setCategories(categories.map((tag) => {
        if(tag._id === id) tag.state = !tag.state; 
        return tag;
    }))
}
  return (
    <div className='bodyNP'>
        <div className='contentOptions'>
          {
            categories.map((category)=> (
              <div key={category._id} onClick={()=>selectTag(category._id)} className={category.state ? 'optionActive': 'option'}> {category.key} </div>
            ))
          }
        
        </div>
    </div>  
  )
}

export default StepThreeComponent
import React from 'react'
import Moment from 'moment';
import './CommentStyles.css';
import { ImageIntoComment } from './CommentStyledComponent';
import { ImageComment } from './CommentComponent.Styled';

const CommentComponent = React.forwardRef(({comment}, ref = null) => {
  return (
    <div className='contentComment' ref={ref}>
                
                <ImageComment image={comment.user_id.profileUrl}/>
                <div className='contentDataComment'>
                    <div className='contentMetaComment'>
                        <div className='nameUserComments'>{comment.user_id?.name}</div>
                        <div className='rateComments'>                  
                        <div className='startActiveComment'>&#9733;</div> <div className='rateCommentNumber'>{comment.score}</div>  
</div>
                        
                    </div>
                    <div className='contentMessage'>
                        <div className='contentImgMsg'>
                        {
                        comment.images.map((imgUrl, index)=>(
                        <div className='imgComment'>
{/*                             <img src={imgUrl} className='imgIntoComment' /> 
 */}                            <ImageIntoComment image={imgUrl} key={'comment-'+index}/>
                        </div>
                        ))
                    }
                        </div>
                        {
                            comment.message
                        }
                    </div>
                    <div className='dataMessage'>
                        {
                            Moment.unix(comment.at/1000).format('DD-MM-YYYY')
                        }
                    </div>
                </div>
            </div>
  )
})

export default CommentComponent
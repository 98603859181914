import React, { useEffect, useState } from 'react'
import './PostComponent.css';
import { BoxImage, ImgUser, PostImage } from './PostStyledComponent';
import Call from './../../assets/call.svg';
import Share from './../../assets/share.svg';
import Navigation from './../../assets/navigation.svg';
import Like from './../../assets/thumb_up_alt.svg';
import LikeFill from './../../assets/thumb_up_alt_fill.svg';

import Chat from './../../assets/chat.svg';
import Compare from './../../assets/swap_horiz.svg';
import Event from './../../assets/event.svg';
import { useNavigate } from "react-router-dom";
import {  GET_MESSAGES,  updateLocationQuery } from '../queries/Post';
import { useMutation } from '@apollo/client';
import Moment from 'moment';
import { helpers } from '../helpers/helpers';
import ShareComponent from '../ShareComponent/ShareComponent';
import { helpersPost } from '../helpers/postHelper';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';
const PostComponent = React.forwardRef(({postC, setShareComponent, setPopUpAlerta, setCommentComponent, popUpAlerta}, ref = null) => {
  const data = useContext(AuthContext);
  const [GetMessagePag, { loading: loadingUpdate, error:errorUp, data: dataUp}] = useMutation(GET_MESSAGES)
  const [updateLocation , addUpLoc] = useMutation(updateLocationQuery);

  const [comment, setComment] = useState(null)
  const [post, setPost] = useState( postC)
  const [day, setDay] = useState(  new Date().getDay())
  const navigate = useNavigate();
  useEffect(() => {
    getDataMessage()
  }, [])
  
  const getDataMessage =() => {
  

    GetMessagePag({
      variables: { input: {_id: post._id, numberPage: 1, size: 1 } }
    }).then(async (data)=>{
      //console.log(data);
      if (data.data ){
      //  console.log(data.data.getComments.message[0]);

       setComment(data.data.getComments.message[0]);
        
      }
      
    }).catch((err )=>{
      
      console.log(err);
    })
  }
 
  //console.log(day)
  //console.log(post.schedule[day])
  const updateLoc = async()=> {
    const like = await helpersPost.likePost( post._id, setPost, updateLocation, post.likeMe);
    if(!data.logIn) {
    setPopUpAlerta({state: true, data: 'login', message1: 'Aun no has iniciado sesion', message2: 'Quieres hacerlo ahora?', 
    acept: ()=>{
      navigate('/login')
      setPopUpAlerta({state: false, data: '', message1: '', message2: '', acept: ()=>{}, cancel: ()=>{}})

    }, 
    cancel: ()=>{
      setPopUpAlerta({state: false, data: '', message1: '', message2: '', acept: ()=>{}, cancel: ()=>{}})

    }})

   }
   
  }
  return ( 
    <div ref={ref}>
       <BoxImage>
          <PostImage onClick={()=>{
            navigate('/post/'+post._id)
          }} image={post.images.length > 0 ? post.images[0]: 'https://foundit.s3.amazonaws.com/documents/notImage.png'} >
            <div className='dataSection'>
              <div className='mid fristData title'>
                <h1 className='titleh1'>{post.post_id?.name}</h1>
                </div>
              <div className='mid secondData'>
                <div className='contentStatus'>
                  {
                    post.schedule[day].open == null && <span className='statusPostSN'>Sin Horario</span>
                  }
                  
                </div>
                <div className='contentScore'>
                  <span className={post.score >= 1 ?'start startActive': 'start'}>&#9733;</span>  
                  <span className={post.score >= 2 ?'start startActive': 'start'}>&#9733;</span>  
                  <span className={post.score >= 3 ?'start startActive': 'start'}>&#9733;</span>  
                  <span className={post.score >= 4 ?'start startActive': 'start'}>&#9733;</span>  
                  <span className={post.score >= 5 ?'start startActive': 'start'}>&#9733;</span>  
                  <span className='score'>{post.score.toFixed(1)}</span>  
                </div>  
              </div>

            </div>
          </PostImage >
      </BoxImage>
      
        {
          !!comment && (<div className='comment'>

        
            <div className='contentMeta'>
              <ImgUser url={comment?.user_id?.profileUrl} />
              <div className='sectionData'>
                <div className='sectionUserFrist'>
                  <span>{/* 910 pts */}</span>
                  <span>{                            Moment.unix(comment?.at/1000).format('DD/MM/YYYY')
    }</span>
                </div>
                <div className='sectionUserSecond'>
                  <span>{comment?.user_id?.name}</span>
                  <span><span className='start2'>&#9733;</span>  {comment?.score.toFixed(1)}</span>
                </div>
              </div>
            </div>
            <div>
              <div className='commentPopulate'>{comment?.message}</div>
            </div>
            </div>)
        }
        
        
      
      <div className='contentActions'>
          <div className='actionOne'>
            {
              post.phone != '' ?
              <a href={`tel:${post.phone}`}>
          <img src={Call} className='actions'
            alt="call" />
            </a> :
             <img src={Call} className='actions'
             alt="call" onClick={()=> setPopUpAlerta({state: true, data: post._id, 
              message2: 'Quieres entrar a detalles a registrar uno?',
               message1: 'Aun no tenemos un telefono registrado',
              acept: ()=> {
                navigate('/post/'+popUpAlerta.data)
                setPopUpAlerta({state: false, data: '', message1: '', message2: '', acept: ()=>{}, cancel: ()=>{}})
      
              },
              cancel:()=> setPopUpAlerta({state: false, data: '', message1: '', message2: '', acept: ()=>{}, cancel: ()=>{}})
            
            })}/>
            }

     <img src={Share} className='actions'
     alt="share" onClick={()=>setShareComponent({state: true, id: post._id})} />
     <img src={Navigation} className='actions'
     alt="navigation" onClick={()=>helpers.openMaps(post.region.latitude, post.region.longitude)} />
     {/* <img src={Compare} className='actions'
     alt="compare" />
     <img src={Event} className='actions'
     alt="event" /> */}
          </div>
          <div className='actionTwo'>
          <img src={Chat} className='actions'
     alt="chat" onClick={() =>setCommentComponent({state: true, name: post.post_id.name, id: post._id})} />
          {
            !post.likeMe ?
            <img src={Like} className='actions'
            alt="like" onClick={updateLoc} /> :
            <img src={LikeFill} className='actions'
            alt="like" onClick={updateLoc} /> 
          }
     
</div>
          
        </div>
    </div>
  )
})

export default PostComponent
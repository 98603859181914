import React from 'react'
import './SnackBar.css'
import {faClose} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SnackBar = ({snackBar, resetSnack}) => {
  return (
    <div className={'snack ' + snackBar.type}>
        <p className='textSnack'>{snackBar.message}</p>
        <span className='iconSnack' onClick={resetSnack}>

        <FontAwesomeIcon icon={faClose} size="1x" color='#fff' style={{marginLeft: '1rem'}}/> 
        </span>

        </div>
  )
}

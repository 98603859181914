import React, { useEffect, useState } from 'react'
import { ImageCube } from './ImageCube'
import closew from './../../assets/closew.svg';
import { getPreSignedUrlQ } from '../queries/Images';
import { useQuery, useMutation } from '@apollo/client';

const ImageToAws = ({img, removeImage, index, name, imgs,setImgs}) => {
    const [loadingAll, setLoadingAll] = useState(true)
    const [urlPresigned, data] = useMutation(getPreSignedUrlQ);
    console.log('name')
    console.log(name)
    /*   console.log(img)
      console.log(id)
      console.log(index) */
        useEffect(() => {
          
            urlPresigned( {
              variables: { input: {_id: name } }
            }).then(async (data)=>{
              console.log(data);
              if (data.data){
                  /* console.log('data de url')
                  console.log(JSON.stringify(data.data.getUrlPost.url)) */
                 upFile(data.data.getUrlPost.url)
                
              }
              
            }).catch((err )=>{
              
              console.log(err);
            })
          return () => {
             // setImgs([]);
          }
        }, [])
        const upFile = async(urlPre)=> {
            console.log('upFile');
            const blob = await b64ToBlob();
            //console.log(data.data.getUrlPost.url);
            const status = await fetch(urlPre, {
                method: "PUT",
                body: blob,
            });
            setLoadingAll(false);
            console.log(status.url)
            let url = status.url.split('?')[0];
            img.url = url;
            setImgs(imgs.map((imgn, indexs)=> {
                if(indexs == index) imgn.url = url
                return imgn
            }))
        }
        const b64ToBlob = async ()=> {
            //console.log(img)
            var byteCharacters = atob(img.data.replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, ''));
            //const byteCharacters = window.atob(encodeURIComponent( img.replace(/\+/g, " ")));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'image/jpg'});
            return blob
        }
        useEffect(() => {
          
        
          return () => {
            
          }
        }, [])
        
  return (
    <ImageCube image={img.data}  >
             {
        !loadingAll && (
            <div className='buttonCloseToSend' onClick={removeImage}>
                        <img src={closew} className='closeAction' 
         alt="close" />
                        </div>
        )
    }
    {
        loadingAll && (<div className='backLoadingImg' >
        <div className="loader"></div>
        </div>)
    }
          </ImageCube>
  )
}

export default ImageToAws
import React, { useEffect, useState, useCallback, useContext } from 'react'
import './NewPostComponent.css';
import close from './../../assets/close.svg';
import StepSection from '../stepsSection/StepSection';
import StepOneComponent from '../StepsNP/StepOneComponent';
import StepTwoComponent from '../StepsNP/StepTwoComponent';
import StepThreeComponent from '../StepsNP/StepThreeComponent';
import StepFourComponent from '../StepsNP/StepFourComponent';
import FinishStepComponent from '../StepsNP/FinishStepComponent';
import { useMutation } from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import { addPostQuery, getLocationsHelpQuery, getPostHelpQuery } from '../queries/Post';
import debounce from 'lodash.debounce';
import SetLocationCurrentPost from '../SetLocationCurrentPost/SetLocationCurrentPost';
import { AuthContext } from '../../context/AuthContext';

const NewPostComponent = ({setRecomend}) => {
    const navigate = useNavigate();
    const data = useContext(AuthContext);

    const [addPost , addPostState] = useMutation(addPostQuery);
    const [postsList , addPostsList] = useMutation(getPostHelpQuery)
    const [locationsList , addLocationsList] = useMutation(getLocationsHelpQuery)
const [posts, setPosts] = useState([])
const [locations, setLocations] = useState([])
    const [form, setForm] = useState({name:'', address: {}, coords: {}, id: '', imgs: [], topics: []})
    const [step, setStep] = useState(1)
    const [selected, setSelected] = useState({name:'', id: ''})
    const [id, setId] = useState('')

    useEffect(() => {
      setStep(1)
      return () => {
          setStep(1)
          
        }
    }, [])
    useEffect(() => {
        setSelected({name:'', id:''})
        if(form.name.length > 2) {
            debouncedChangeHandler(form.name);
        } else {
            setPosts([])
        }
        
    }, [form])
    const handlePosts = (value)=> {
          console.log(value)
        postsList({
            variables: { input: 
                { 
                name: value,
               limit: 5, 
               skip: 0
            } 
              }
          }).then((resp)=>{
          //  console.log(resp.data.getPostRegex);
            
            if(resp.data.getPostRegex.post.length > 0 ) {
            //  console.log('expi');
              setPosts(resp.data.getPostRegex.post)
            
            } else {
      
            }
           
                  
               }).catch((e)=> {
                   console.log(e);
               })
      }  
      const debouncedChangeHandler = useCallback(
        debounce(handlePosts, 1000)
      , []);  
     
      const nextStep = async ()=> {
        if(form.name.length < 2) return data.setSnackBar({state: true, message: 'Agrega un nombre a la publicacion', type: 'warning'})
        if(step == 1 && selected.id != '') {
            setStep(1.5)
            locationsList({
                variables: { input: 
                    { filter: 
                        `[ { "$geoNear": {
                          "near": {
                              "type": "Point",
                              "coordinates": [-100.16639208421111,25.714272402849375]
                          },
                          "distanceField": "distance",
                          "spherical": true,
                          "maxDistance": 10000
                      }}]`,
                    id: selected.id,
                   limit: 5, 
                   skip: 0
                } 
                  }
              }).then((resp)=>{
               // console.log(resp.data);
                
                if(resp.data.getLocationsRegex.locations.length > 0 ) {
                  //console.log('expi');
                  setLocations(resp.data.getLocationsRegex.locations)
                
                } else {
          
                }
               
                      
                   }).catch((e)=> {
                       console.log(e);
                   })
        }  else  if(step == 1.5) {
            setStep(2)
            setForm({...form, id:selected.id})
            
        } else {
            if(step === 3 && form.topics.length < 1 ) return data.setSnackBar({state: true, message: 'Selecciona almenos una categoria', type: 'warning'})
            setStep(step+1)
           
        }
        if(step == 4) {
            const imgToSend = await Promise.all(form.imgs.map(img=>img.url))
            console.log(form.topics)
            addPost({
                variables:{
                    input: { 
                        //post
                        name: form.name,
                        description: '',
                        type: 'place',
                        region: {latitude: form.directions.coords.lat, longitude: form.directions.coords.lng}, 
                        address: form.directions.address_components,
                        showAddress: form.directions.showAddress,
                        images: imgToSend,
                        id: form.id,
                        topics: form.topics,
                        
                     }
                 }}).then((data)=>{

                     console.log('respuesta')
                     console.log(data.data.addPost.message)
                     setId(data.data.addPost.message)
                    /*  navigation.goBack();
                     navigation.navigate('DataPost', {_id: data.data.addPost.message, distance: 'km'}) */
                     //reset();
                 }).catch((e)=> {

                     console.log(e);
                 })
        }
        
        
    }
    const backStep = ()=> {
        if(step ==1) {
            setRecomend(false)
        } else if (step == 1.5) {
            setStep(1)

        } else {
            
            setStep(step-1)
        }
    }
    return (
        <div className='totalContent'>
        {
            (step < 5 && step !== 2) &&
            <div className='head'>
               
            <div className='closeContent' onClick={()=> setRecomend(false)}>

                <img src={close} className='closeAction'
         alt="close" />
            </div>
                <div className='titleNP'>
                   {step ==1 && 'Registra el Nombre'} 
                   {(step ==3 || step == 3.5) && 'Categorias'} 
                   {(step ==4 || step == 4.5) && 'Imagenes'} 
                   {step ==1.5 && 'Ubicacion'} 
                </div>
            </div>
        }
       
        {
            step == 1 &&
        <StepOneComponent form={form} setForm={setForm} posts={posts} selected={selected} setSelected={setSelected} />
        }
        {
            step == 2 &&
        <StepTwoComponent form={form}  setForm={setForm} />
        }
        {
            (step ==3 || step == 2.5) &&
        <StepThreeComponent form={form}  setForm={setForm} />
        }
         {
            (step ==4 || step == 3.5) &&
        <StepFourComponent form={form}  setForm={setForm} />
        }
         {
             (step ==5 || step == 4.5) &&
        <FinishStepComponent setRecomend={setRecomend} goToPost={()=>navigate('/post/'+id)} />
        }
         {
            step == 1.5 &&
                <SetLocationCurrentPost locations={locations} handleNewLocation={()=>nextStep()}/>
        }
        {
            step < 5 &&
            <div className='footer'>
            {
                (step == 1 && selected.id == '') && 
            <div className='commentNP'>Te mostramos algunas coincidencias para asegurarnos que sea un nuevo post</div>
            }
            {
                (step == 1.5) && 
            <div className='commentNP'>Te mostramos algunas ubicaciones, para evitar repetir el post</div>
            }
            {
                step == 3 && 
            <div className='commentNP'>Las categorias nos ayudan a diferenciar que mostrarte</div>
            }
            {
                step == 4 && 
            <div className='commentNP'>Las imagenes ayudan a que la publicacion sea mas atractiva para los suarios</div>
            }
            {
                (step == 1 && selected.id != '') && 
            <div className='commentNP'>Da clic en siguiente para buscar una ubicacion para {selected.name.toUpperCase()}</div>
            }
            {/* <StepSection step={step} /> */}
            <div className='sectionButtons'>
                <button className='backButton btn' onClick={()=>backStep()}>Atras</button>
                <button className='nextButton btn' onClick={()=>nextStep()}>Siguiente</button>
            </div>
        </div>
        }
        
    </div>
  )
}

export default NewPostComponent
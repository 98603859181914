import React, { useContext } from 'react'

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import MenuComponent from '../menuComponent/MenuComponent';
import LoginNewUser from '../modal/LoginNewUser';
import NewPostComponent from '../NewPostComponent/NewPostComponent';
import PopUpAlert from '../PopUpAlert/PopUpAlert';
import { TopBar } from '../TopBar';
import HomeScreen from './HomeScreen';

const PrincipalScreen = ({cleanLocalStorage,setMenus, setLogin,setRecomend, recomend, Login, Menus }) => {
  const data = useContext(AuthContext);
  const navigate = useNavigate();

  const [commentComponent, setComment] = useState({state: false, name: '', id: ''})
  const [popUpAlerta, setPopUpAlerta] = useState({state: false, data: '', message1: '', message2: '', acept: ()=>{}, cancel: ()=>{}})
  const setNewPost = ()=> {
    if(data.logIn) {
      setRecomend(true)

    } else {

      setPopUpAlerta({state: true, data: '', 
      message1: 'Para registrar una publicacion debes iniciar sesion', 
      message2: '¿Quieres hacerlo ahora?', acept: ()=>{
        navigate('/login');
        setPopUpAlerta({state: false})
      }, cancel: ()=>{setPopUpAlerta({state: false})}})
    }
  }
  useEffect(() => {
    
  }, [])
  
  
  return (
    <div className="App">

      { !commentComponent.state && !recomend && <TopBar setMenus={setMenus} setRecomend={setRecomend} setPopUpAlerta={setPopUpAlerta} setNewPost={setNewPost}/>}
      <HomeScreen setLogin={setLogin}  setComment={setComment} commentComponent={commentComponent} setNewPost={setNewPost} />
   
      {
        Menus &&
        <MenuComponent setMenus={setMenus} logOut={cleanLocalStorage} />
      }
      {
        recomend && <NewPostComponent setRecomend={setRecomend}/>
      }
      {
        popUpAlerta.state && <PopUpAlert popUpAlerta={popUpAlerta} />
      }
    </div>
  )
}

export default PrincipalScreen
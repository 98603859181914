import React, { useCallback, useContext, useEffect, useState } from 'react'
import './TopBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faMagnifyingGlass, faPlus, faLocationDot, faArrowsLeftRight, faLocationArrow, faPuzzlePiece} from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../context/AuthContext';
import Logo from './../assets/logoExpiMini.svg';
import { useMutation} from '@apollo/client';

import { useNavigate } from 'react-router-dom';
import StepTwoComponent from './StepsNP/StepTwoComponent';
import debounce from 'lodash.debounce';
import { getHelpSearch } from './queries/Post';

export const TopBar = ({setMenus, setPopUpAlerta, setRecomend, setNewPost}) => {
const data = useContext(AuthContext);
const [showLocation, setShowLocation] = useState(false);
const [form, setForm] = useState({coords: {lat: 0, lng: 0}});
const initialSearching = {state: false, value: '', categories: [], locations: []};
const [searching, setSearching] = useState(initialSearching);
const [helpSearch , getHelpSearchMut] = useMutation(getHelpSearch);
const navigate = useNavigate();
useEffect(() => {
//setSelected({name:'', id:''})
//console.log(searching.value)
  if(searching.value.length > 2) {
      debouncedChangeHandler(searching.value);
  } else {
      setSearching({...searching, categories: [], locations: []})
  }

}, [searching.value])
useEffect(() => {
  let isMounted = true;               // note mutable flag
 //console.log(data.location.simpleAddress)
/*   const state = data.location.address_components.filter(component => component.types.includes('locality'))
  if(isMounted) setCity(state[0].short_name);
   */
  return () => { isMounted = false }; // cleanup toggles value, if unmounted


}, [])

const handlePosts = (value)=> {
 /*  console.log('buscando')
  console.log(value) */

helpSearch({

    variables: { input: 
        { 
        match: JSON.stringify({name: {$regex: value, $options: 'i'}}),
        matchC: JSON.stringify({key: {$regex: value, $options: 'i'}}),
       limit: 3, 
       skip: 0
    } 
      }
  }).then((resp)=>{
    const categories = resp.data.getHelpSearch.categories;
    const locations = resp.data.getHelpSearch.locations;
    setSearching({state: true, value, categories, locations})

   
          
       }).catch((e)=> {
           console.log(e);
       }) 
}  
const debouncedChangeHandler = useCallback(
debounce(handlePosts, 1000)
, []); 
const handleChange = (data)=> {
 // console.log(searching)
setSearching({...searching, value: data.target.value})
}

return (
    <div className='bodyTopBar'>
   
   
  {
    showLocation && <div className='showLocationComponent'>
      <StepTwoComponent initial={data.location?.coords}  form={form}  setForm={setForm} />
      <div className='sectionButtonsUpdateLocation'>
        <button className='buttpnUpLocC' onClick={()=>setShowLocation(false)}>
          Cancelar
        </button>
        <button className='buttpnUpLocA' onClick={()=>{
          data.setAddress(form.directions)
          setShowLocation(false)
          }}>
          Aceptar
        </button>
      </div>
    </div>
  }
  
    <div className='topBar'>
      {
        !searching.state && <div>
        <button onClick={() =>setMenus(true)} className='mobile'>

        <FontAwesomeIcon icon={faBars}  size="2x" color='#fff'/>
        </button>
        <button onClick={setNewPost} className='addPost'>

        <FontAwesomeIcon icon={faPlus}  size="2x" color='#fff'/>
        </button>
      </div>
      }
      {
        !searching.state &&
      <img src={Logo} style={{width:'9rem', position: 'absolute', left: '50%', marginLeft: '-4.5rem' }} />
      }
      {
        searching.state && <div className='searchBoxContent'>

          <input className='searchBox' id='searchingBox' autoFocus autoComplete='off'
          onBlur={()=>{
           setTimeout(()=> {

             setSearching(initialSearching) 
           },500);
          }}
          value={searching.value} 
          onChange={handleChange}></input>

          <button
          onClick={()=>data.setSearching({value: searching.value, type: 'locations'})} >

              <FontAwesomeIcon icon={faMagnifyingGlass} size="2x" color='#075EA4' 
              style={{marginRight: '1rem'}}/>
          </button>
          <div className='contentBoxHelps'>
       
        {
          searching.locations.map(loc=><button className='buttonSearching' key={loc._id}
          onClick={()=> {navigate('/post/'+loc._id)
          data.setSearching({value: '', type: 'locations'})
        }
          }>
            {loc.name}
            <FontAwesomeIcon icon={faLocationArrow}  size="1x" color='#075EA4'/>

            </button>)
        }
        {
          searching.categories.map(loc=><button className='buttonSearching' key={loc._id}
          onClick={()=>data.setSearching({value: loc.key, type: 'categories'})} >
            {loc.key}
            <FontAwesomeIcon icon={faPuzzlePiece}  size="1x" color='#075EA4'/>

            </button>)
        }
      </div>
        </div>
      }
  

        <div className='contentCat'>
        {

!searching.state && <button className='searchButton' onClick={()=>{
  setSearching({...searching, state: true})
  
  }}>

<FontAwesomeIcon icon={faMagnifyingGlass} size="2x" color='#fff'/>
</button>
        }
        
        </div>
        
    </div>
    <div className='optionsBar'>
      <button className='buttonOptionsBar' onClick={()=>setShowLocation(true)}>
      <FontAwesomeIcon icon={faLocationDot} size="1x" color='#fff'style={{marginRight: '1rem'}}/>


      {data.location.simpleAddress?.locality}
      </button>
      <button className='buttonOptionsBar'onClick={()=> data.handleShowRange()}>
      <FontAwesomeIcon icon={faArrowsLeftRight} size="1x" color='#fff'style={{marginRight: '1rem'}}/>

      Rango {data.range} KM
      </button>
    </div>
    </div>
  )
}

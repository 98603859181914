import React from 'react'

const PopUpAlert = ({popUpAlerta}) => {
  console.log(popUpAlerta)
  return (
    <div className='popUpAlerta'>
        <div className='bodyAlert'>
          <div className="titleAlert">{popUpAlerta.message1}</div>
          <div className="titleAlert">{popUpAlerta.message2}</div>
          <div className='sectionButtonsAlert'>
          <button className='buttonAlert' onClick={popUpAlerta.cancel}>Cancelar</button>
          <button className='buttonAlert' onClick={popUpAlerta.acept}>Aceptar</button>
        </div>
        </div>
        
    </div>
  )
}

export default PopUpAlert
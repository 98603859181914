import styled from 'styled-components'

const ImageIntoComment = styled.div`
width:100%;
height:100%;
margin-bottom:.5rem;
padding: 1rem 0;
position: relative;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
border-radius: 1rem;
background-image: url(${props => props.image || 'https://foundit.s3.amazonaws.com/documents/notImage.png'});
`;



export { ImageIntoComment};
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import './CommentsComponent.css';
import Send from './../../assets/send.svg';
import Camera from './../../assets/camera_enhance.svg';
import { useNavigate } from 'react-router-dom';
import WebCamComponent from '../WebCamComponent/WebCamComponent';
import { ImageToSend } from './CommentsStyledComponent';
import close from './../../assets/close.svg';
import moneyBlue from './../../assets/moneyBlue.svg';
import ImageToSendComponent from './ImageToSendComponent';
import { addMessageQuery, GET_MESSAGES } from '../queries/Post';
import { useMutation } from '@apollo/client';
import CommentComponent from '../commentComponent/CommentComponent';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../context/AuthContext';
import { compressImage } from '../../utils/images';

const CommentsComponent = ({name, id, setComments, setData=()=>{}}) => {
    const navigate = useNavigate();
    const [addMessage , addMessageState] = useMutation(addMessageQuery);

    const [imgs, setImgs] = useState([])
    const initialComment = {msg: '', pictures: [], scores: [
        {name: 'Costo', score: 0, icon: moneyBlue},
        {name: 'Servicio', score: 0, icon: moneyBlue},
        {name: 'Tiempo', score: 0, icon: moneyBlue},
        {name: 'Instalaciones', score: 0, icon: moneyBlue},
], location_id: id, totalScore: 0};
    const [comment, setComment] = useState(initialComment)
    const [commentsList, setCommentsList] = useState([])
    const [hasMore, setHasMore] = useState(true)

    const [stepMessage, setStepMessage] = useState(1)
    const [preFiles, setPreFiles] = useState([])
    const [picture, setPicture] = useState(false)
    const [webCam, setWebCam] = useState(false)
    const [page, setPage] = useState(1);
    const [GetMessagePag, { loading: loadingUpdate, error:errorUp, data: dataUp}] = useMutation(GET_MESSAGES)
    const context = useContext(AuthContext);

    //console.log('idDD ' + id)
  
    useEffect(() => {
        getDataMessage();
      }, [page])
      useEffect(() => {
        if(preFiles.length) {
            setImgBlob()
        }
      }, [preFiles])
      const setImgBlob = async ()=> {
        const ready = await Promise.all(preFiles.map(async file=>({data: await blobToBase64(file), url: ''})))
         setImgs([...imgs, ...ready])

      }
   /*  const moreData = ()=>{
        if(addMessageState.data) {
            //setInsertCode(true);
            //dataUpd(dataUp);
            console.log(addMessageState.data.addMessage);
            setStepMessage(1);
            setComment(initialComment)
            setCommentsList([addMessageState.data.addMessage,...commentsList]);
            setData(addMessageState.data)
        }
    }; */
    const observer = useRef(); // (*) 
    const moreDataRef = useCallback( // (*) 
    (node) => { 
      //console.log(node)
      if (loadingUpdate) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !loadingUpdate && hasMore) {
          moreData();
          //console.log(loadingUpdate);
          /* changeLoading();
          setnumberPage((prev) => prev + 1); */
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingUpdate, hasMore] 
    ); 
  const getDataMessage =() => {
  let size = 15;
    if(page == 1 && commentsList.length != 0) return

    GetMessagePag({
      variables: { input: {_id: id, numberPage: page, size } }
    }).then(async (data)=>{
      //console.log(data);
       if (data.data ){
       // console.log(data.data.getComments.message);
        setCommentsList([...new Set([...commentsList, ...data.data.getComments.message])]);
        if(data.data.getComments.message.length < size) {
          setHasMore(false)
        }
        
      } 
      
    }).catch((err )=>{
      
      console.log(err);
    })
  }
    const openWebcam = ()=> {
        setPicture(false)
        setWebCam(true)
    }
    const removeImage = (data)=>{
        
        setImgs(imgs.filter((img, index)=> index != data && img)) 
      
    }
    const dataChange = (data)=> {
        //console.log(data.target.value)
        setComment({...comment, msg: data.target.value, pictures: imgs})
      }
  
      const nextStep = () => {
        if(comment.msg == '') return alert('El mensaje es obligatorio.')
        setComment({...comment, pictures: imgs})
        //console.log(imgs)
        setStepMessage(2)
      }
      const setScoreFeature = (name, val) => {
        setComment({...comment, scores: comment.scores.map(score=> name == score.name ? {...score, score: val}:score)})
      }
      const sendComment = async() => {
          //console.log(comment);
        await addMessage({

            variables:{
                input: { location_id: comment.location_id, msg: comment.msg, pictures: comment.pictures.map(pic=>pic.url), totalScore: comment.totalScore, scores: comment.scores}
             }}).then((addMessageState)=> {
              if(addMessageState.data) {
                //setInsertCode(true);
                //dataUpd(dataUp);
                //console.log(addMessageState.data.addMessage);
                setStepMessage(1);
                setComment(initialComment)
                setCommentsList([addMessageState.data.addMessage,...commentsList]);
                setData(addMessageState.data)
            } 
             }).catch(e=>{
              console.log(e)
              context.setSnackBar({state: true, message: e.message, type: 'error'})
             })
             
             /*.then((resp: any)=>{
                setShowOption(false);
                setScore(0);
                setShowScore(false);
                setImagesUpload([]);
                Keyboard.dismiss();
                //search.current?.
                 console.log('respuesta')
                 let com = [resp.data.addMessage,...dataComments]

                setComments(com)
                 console.log(JSON.stringify(resp.data.addMessage))
                 setMessage('');
                 
                }).catch((e)=> {
                    alertAll('error al ingresar comentario');
                    console.log(e);
                })
                */

      }
      const moreData =()=> {
       // console.log('more data')
        setPage(page+1)
      }
      const selectImage = ()=> {
        document.getElementById('inputFile').click()
      }
      const setFiles = async (documents) => {
       // const blob = new Blob([documents.target.files[0]], {type: 'image/jpg'});
        const files = documents.target.files;
        
            setPreFiles([...files /* ...imgs, {data: await blobToBase64(files[i]), url: ''} */])
        
        setPicture(false);
      }
      /* const toBase64 = async (file) => {
        const reader = new FileReader();
        return reader.readAsDataURL(file);
      } */
      const blobToBase64 = blob => {
        return new Promise(async (resolve, _) => {
          const newBlob = await compressImage(blob);

          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(newBlob);
        });
      }
  return (
    
      webCam ? <WebCamComponent setBack={setWebCam} pictures ={imgs} setPictures = {setImgs}/> :
  
    <div className='contentComments'>
                <input type='file' id="inputFile" onChange={setFiles} style={{display: 'none'}} multiple/>

        {
            picture && (<div className='contentPictureOptions'>
                <div className='contentOptionsComments'>
                    <div className='headerOptionsPictures'>
                    <div className='closeContentComment' onClick={()=> setPicture(false)}>
                <img src={close} className='closeAction' alt="close" />
        </div>
                        Selecciona una opcion</div>
                    <div className='optionPicture' onClick={openWebcam}>Camara</div>
                    <div className='optionPicture' onClick={selectImage}>Galeria</div>
                </div>
            </div>)
        }
        
        <div className='contentHeaderComments'>
        <div className='closeContentComment' onClick={()=> setComments({state: false, id: '', name: ''})}>
                <img src={close} className='closeAction' alt="close" />
        </div>
        <div className='titleComments'>

        {name}
        </div>
        </div>
        <div className='contentBodyComments' style={commentsList.length == 0 ? {display: 'flex'}: {}} >
        {
                commentsList.map((comment, index) => 
                (commentsList.length) == (index + 1) ? <CommentComponent ref={moreDataRef} key={comment._id} comment={comment} />:
                <CommentComponent key={comment._id} comment={comment} />)
            }
        {
          hasMore ? <div className="contentPreloader">
          <div className="box" >
            <div className="container">
              <span className="circle"></span>
              <span className="circle"></span>
              <span className="circle"></span>
              <span className="circle"></span>
            </div>
          </div>
          </div>: commentsList.length === 0 && <div className='notFoundPosts' style={{paddingTop: '6rem'}}>
          <FontAwesomeIcon icon={faMessage}  size="8x" color='#ccc'/>
          <p className="textNotFoundPosts">Esta publicacion aun no cuenta con experiencias, ingresa la primera que esperas.</p>
          <div className="buttonNotFoundContent">
            { !context.logIn && 
          <button className="buttonNotFoundAdd"  onClick={()=>     navigate('/login')
        }>Iniciar sesion</button>
      }
          </div>
      </div>
        }
        </div>
        {
          context.logIn ? (
            stepMessage === 1 ? (

                <div className='contentAddComments'>
        <div className='contentPictures'>
            {
                imgs.map((img, index)=>(
                    <ImageToSendComponent key={`imgToSend${index}`} index={index} img={img} removeImage={removeImage} id={id} imgs={imgs} setImgs={setImgs}/>
                    
                ))
            }
        </div>
        <div className='contentInputComments'>
        <button className='btn-camera' onClick={()=>setPicture(true)}>
            <img src={Camera} className='cameraCommentImg'
     alt="share" />
            </button>
            <input type="text" className='inputComments' value={comment.msg} onChange={dataChange}/>
            <button className='btn-send' onClick={nextStep} >
            <img src={Send} className='sendCommentImg' 
     alt="share" />
            </button>
        </div>
        </div>
            ) : 
            (

                <div className='contentAddScore'>
                    {
                                comment.scores.map((score,index)=>(
                                    <div className='sectionFeature' key={score.name+index}>
                        <div className='titleFeature'>
                            
                            <div className='titleText elementFeature'>

                            {score.name}
                            </div>
                            <img src={score.icon} className='closeAction elementFeature' alt="money" />

                        </div>
                        <div className='scoreFeature'>
                        <div className='contentScoreFeature'>
                  <span className={score.score >= 1 ?'start startActive': 'start'} onClick={()=>setScoreFeature(score.name, 1)}>&#9733;</span>  
                  <span className={score.score >= 2 ?'start startActive': 'start'} onClick={()=>setScoreFeature(score.name, 2)}>&#9733;</span>  
                  <span className={score.score >= 3 ?'start startActive': 'start'} onClick={()=>setScoreFeature(score.name, 3)}>&#9733;</span>  
                  <span className={score.score >= 4 ?'start startActive': 'start'} onClick={()=>setScoreFeature(score.name, 4)}>&#9733;</span>  
                  <span className={score.score >= 5 ?'start startActive': 'start'} onClick={()=>setScoreFeature(score.name, 5)}>&#9733;</span>  
                </div>
                        </div>
                    </div>
                                ))
                            }
                            <div className='buttonSendFeature' onClick={sendComment}>Enviar</div>
                    
        
                </div>
            )) : null
        }
 
        
       
        
    </div>
  )
}

export default CommentsComponent
import React, { createContext, useReducer, useState } from "react";
//import { LoginData, User, VerifyCodeData } from '../interfaces/UserInterface';
import { useQuery, gql, useMutation } from '@apollo/client';

import { useEffect } from "react";
/* import useUploadFile from "../hooks/useUploadFile";
 *//* type AuthContextProps = {
    ready: boolean;
    errorMessage: string;
    token: string | null;
    user: User | null;
    state: 'checking' | 'authenticated' | 'not-authenticated';
    readyState: () => void;
    signIn: (loginData: LoginData) => void;
    verifyCodeIn: (veyfyCodeData: VerifyCodeData) => void;
    updateUser: (token: User, goBack: Function) => void;

    //uploadImage: (token: string, setPerfil: Function) => void;
    signUp: () => void ;
    removeError: () => void;
    logOut: () => void;
    loadingVerify: boolean
    loadingUpdate: boolean
}

const initialState: AuthState = {
    state: 'checking',
    token: null,
    user: null,
    errorMessage: '',
    ready: false
    
}
*/


const AuthContext = createContext();

/* export const AuthProvider = ({children}: any) => {
  
    const verifyEmail = gql`
  mutation verifyEmail ($input: verifyEmailInput ) {
    verifyEmail (input: $input) {
      status
      message
    }
    
  }
`;
    
const Mimg = gql`
mutation GetUrlPhotoProfile($input: PreSignedProfileInput){
    GetUrlPhotoProfile(input: $input){
        url
    }
}
`;

const gqlUpdateUser = gql`
mutation updateUser($input: userInput){
    updateUser(input: $input){
        name
        email
        img
        
    }
}
`;
const [updateM, { loading: loadingUpdate, error:errorUp, data: dataUp}] = useMutation(gqlUpdateUser)
const [getUrl, { loading: loadingUrl, error:errorUrl, data: dataImg}] = useMutation(Mimg)
    const [sendCode, { data: dataVerify, loading: loadingVerify }]= useMutation(verifyEmail);
    const [state, dispatch] = useReducer(authReducer, initialState);
    //const {file, uploadFile} = useUploadFile();

    const signIn= async ({email, password}: LoginData) => {
        try {

        } catch(e) {

        }
    };

    useEffect(() => {
        account();
    }, []);

    const account = async () => {
        let token = await localStorage.getItem('token');
        console.log('token guardado: '+ token);
        let user = await localStorage.getItem('user')
        console.log('usuario: '+ user);
        if (user && token) {
            await dispatch({type: 'signIn', payload: {user: JSON.parse(user), token: token}})
        } else {
            console.log('no signin')
        }
       
        
    }
   
    const verifyCodeIn= ({token, code, next}: VerifyCodeData) => {
            
            sendCode({
                variables:{
                    input: {token , code}
                }}).then(async (data: any)=>{
                    dispatch({type: 'verifyCodeIn', payload: {token: data.data.verifyEmail.message}})
                    let token = await localStorage.setItem('token', data.data.verifyEmail.message);

                    await next();
                }

                ).catch((err: any)=>{

                    console.log(err);
                    //alertAll(err.graphQLErrors[0].message);
                })
                setStep(step+1);
        
    };
    const uploadImage= async (uri: string, setPerfil: Function) => {
        try {

            getUrl({
                variables:{
                    input: {uri}
                }}).then( (response: any) => {
                    setPerfil(response.data.GetUrlPhotoProfile.url.split('?')[0]);
                    uploadFile(response.data.GetUrlPhotoProfile.url, uri);
                   
                }).catch((e: any)  => {
                    console.log("Error URL: " + JSON.stringify(e))
                    alertAll(e.graphQLErrors[0]);

                })
               
            } catch (e){
                alertAll(e.graphQLErrors[0]);

            }
    };
    const updateUser = (user: User, setStep: Function) => {
        console.log('update: ');
        console.log(user);
        updateM({
            variables:{
                input: {...user}
            }}).then(async (data: any)=>{
                await dispatch({type: 'signIn', payload: {user: user, token: null }})
                console.log('REPUESTA UP '+JSON.stringify(data.data.updateUser));
                await localStorage.setItem('user', JSON.stringify(data.data.updateUser));

               // await AsyncStorage.setItem('user', JSON.stringify(data.data.updateUser));
                
                setStep()
                await AsyncStorage.setItem('token', data.data.verifyEmail.message);
                await next();
            }).catch((err: any)=>{

                console.log(err);
                //alertAll(err.graphQLErrors[0].message);
            })
    };
    const signUp= () => {};
    const removeError= () => {};
    const logOut= () => {};
    const readyState = async () => {
        await dispatch({type: 'ready'})

    };
    const lookingNow = async (val: String) => {
        await dispatch({type: 'lookingNow', payload:{value: val}})

    };
   
} */

/* 

state: 'checking' | 'authenticated' | 'not-authenticated';
updateUser: (token: User, goBack: Function) => void;
signUp: () => void ;
signIn: (loginData: LoginData) => void;
verifyCodeIn: (veyfyCodeData: VerifyCodeData) => void;
removeError: () => void;
logOut: () => void;
loadingVerify: boolean
loadingUpdate: boolean
readyState: () => void;

ready: boolean;
    errorMessage: string;
    token: string | null;
    user: User | null;

    uploadImage: (token: string, setPerfil: Function) => void;
*/
export {AuthContext}
import React, { useEffect, useState } from 'react'
import { faCamera} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PictureOptionsComponent from '../PictureOptions/PictureOptionsComponent';
import {ImageCube, ImageProfile} from './ImageCube'
import ImageToAws from './ImageToAws';

const StepFourComponent = ({form, setForm}) => {
  const [picture, setPicture] = useState(false)
  const [imgs, setImgs] = useState([])

  
  useEffect(() => {
    setPicture(false);

   setForm({...form, imgs: imgs})
  }, [imgs])
  const removeImage = (data)=>{
        
    setImgs(imgs.filter((img, index)=> index != data && img)) 
  
}
 
  return (
    <div className='bodyNP'>
      {
            picture && <PictureOptionsComponent setPicture={setPicture} imgs={imgs} setImgs={setImgs} />
        }
      <div className='contentOptions'>
        <div className='contentImages'>

        {
          imgs.map((img,index)=><ImageToAws img={img} index={index} name={form.name} removeImage={()=>removeImage(index)} imgs={imgs} setImgs={setImgs}/>)
        }
        </div>
         
      </div>
      <button className='botonAddImmage'  onClick={()=>setPicture(true)}>
          <FontAwesomeIcon icon={faCamera} className='iconGearSettings'  size="1x" color='#fff'/>
          Agregar

        </button>
    </div> 
  )
}

export default StepFourComponent
import { calculateNewValue } from '@testing-library/user-event/dist/utils'
import close from './../../assets/close.svg';
import './ImagenesComponent.css';
import React from 'react'
const ImagesComponent = ({images, setImagesData, name='Post', turnBack}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
      <div className='closeContentComment' onClick={turnBack} >
                <img src={close} className='closeAction' alt="close" />
        </div>
      <div style={{height: '5rem', fontSize: 16, fontWeight: 700, backgroundColor: '#2BABE2', color: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Imagenes {name}
      </div>
      <div style={{ overflow: 'auto', flex:1, maxHeight: 'calc( 100vh - 40px)' }}>

{
  
  images.map(img=>(
    <img src={img} style={{width:'100%'}} />
    ))
  }
  </div>
    </div>
  )
}

export default ImagesComponent
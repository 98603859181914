import React, { useEffect, useState } from 'react'
import './TopicsComponent.css'
import close from './../../assets/close.svg';
import { getCategoriesQuery } from '../queries/Post';
import { useQuery } from '@apollo/client';

const TopicsComponent = ({ popUpTopics, context}) => {
  const { loading, error, data: dataCategories } = useQuery(getCategoriesQuery);
    const [categories, setCategories] = useState([]);
      const selectTag = (id) => {
        console.log(id)
        setCategories(categories.map((tag) => {
            if(tag._id === id) tag.state = !tag.state; 
            return tag;
        }))
    }
    /* useEffect(() => {
      if(dataCategories) {
        console.log(dataCategories)
        setCategories(dataCategories.getCategory.categories.map(category=>({...category, state: false})))
        }
    }, [dataCategories]) */
    useEffect(async () => {
      if(dataCategories) {

      console.log(popUpTopics.data)
      console.log(dataCategories.getCategory.categories)
     const current = await Promise.all(dataCategories.getCategory.categories.map(tag=> {
        console.log(tag)
        console.log(popUpTopics.data.includes(tag.key))
        let state = popUpTopics.data.includes(tag.key)
        return {...tag, state}
      } ));
      console.log(current)
      setCategories(current)
    }
     
    }, [dataCategories])
    
  return (
    <div className='containerTopics'>
        <div className='sectionTop'>
            <div className='buttonClose' onClick={()=>popUpTopics.cancel()}>
                <img src={close} className='closeAction' alt="close" />
            </div>
            <div className='titleTopic'>
                Temas
            </div>
        </div>
        <div className='containerBodyTopics'>
        {
            categories.map((category)=> (
              <div key={category._id} onClick={()=>selectTag(category._id)} className={category.state ? 'optionActive': 'option'}> {category.key} </div>
            ))
          }
        </div>
        <div className='sectionButtons'>
                <button className='backButton btn' onClick={()=>popUpTopics.cancel()}>Cancelar</button>
                <button className='nextButton btn' onClick={()=>
                { 
                  const current = categories.filter(cat=>cat.state);
                  if(current.length > 0) {

                    popUpTopics.acept(current);
                  } else {
                    context.setSnackBar({state: true, message: 'Selecciona almenos una categoria', type: 'warning'})
                  }
                }}>Aceptar</button>
            </div>
        </div>
  )
}

export default TopicsComponent
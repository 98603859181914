import styled from 'styled-components'

const ImgHelp = styled.div`
width: 6rem;
height: 6rem;
border-radius: .8rem;
background-repeat: no-repeat;
background-position: center;
background-size: cover;

background-image: url(${props => props.image || 'https://foundit.s3.amazonaws.com/documents/notImage.png'});
`;


export { ImgHelp};
import { gql} from '@apollo/client';

const getPreSignedUrlQ = gql`
mutation getUrlPost($input: PreSignedProfileInput) {
    getUrlPost(input: $input) {
            url
        }
      }
`;

export {getPreSignedUrlQ}
export const helpersPost = {
    likePost: async (id, setPost, updateLocation, likeMe) => {
        let user = await JSON.parse(localStorage.getItem('user'));
        if(user == null) return ({state: false})
        let filter = JSON.stringify({_id: id});
        updateLocation({
          variables: { input: { filter, 
          data: !likeMe} },
        }).then((resp)=>{
          console.log(resp)
          setPost((post)=> ({...post, likeMe: !likeMe}))
          return ({state: true})
               
        }).catch((e)=> {
                //alertAll('error al ingresar comentario');
                
                console.log(e);
                return ({state: false})
        })
      }

}
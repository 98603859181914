import React, {  useState, useEffect } from 'react'
import icono from './../../assets/icono.jpg';
import upImage from './../../assets/add_photo_alternate.svg';
import deletIcon from './../../assets/delete.svg';
import changeIcon from './../../assets/cached.svg';
import {ImageProfile} from './LoginNewUser.Styled'
import sobre from './../../assets/sobre.jpg';
import returnPage from './../../assets/return.svg';
import PictureOptionsComponent from '../PictureOptions/PictureOptionsComponent';
import './LoginNewUser.css';
import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import {loginFacebook, queryAuthAccount, queryLogIn } from '../queries/User';
import { useNavigate } from 'react-router-dom';
import SetCodeComponent from '../setCodeComponent/SetCodeComponent';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';
import ReactDOM from 'react-dom';
import FacebookLogin from 'react-facebook-login';
import { environment } from '../../environment/environment';

 const LoginNewUser = ({close}) => {
    console.log('apiii')
    console.log(                    environment.appFacebookId
        )
    const navigate = useNavigate();
    const [imgs, setImgs] = useState([])
    const [selectedimg, setSelectedImg] = useState([])

    const [Login, setLogin] = useState(true);
    const [picture, setPicture] = useState(false);
    const [InsertCode, setInsertCode] = useState(false);
    const [form, setForm] = useState({Email:'', User:'', Password:'', profileUrl: ''});
    const [data, setData] = useState({});
    const {register, handleSubmit}= useForm();
    const [addAccount, {loading: loadingAddAccount}] = useMutation(queryAuthAccount);
    const [addFacebook, {loading: loadingLoginFacebook}] = useMutation(loginFacebook);
    const [loginMut, {loading: loadingLogIn}] = useMutation(queryLogIn);
    const userContext = useContext(AuthContext);

   useEffect(() => {
    if(imgs.length > 0) {
      //  console.log(imgs)

        setSelectedImg([imgs[imgs.length-1]]);
        setPicture(false)
    }
}, [imgs])

/* const prepareImage = async ()=> {
    const ready = {data: await blobToBase64(imgs[imgs.length-1]), url: ''};
    if(imgs.length > 0) setSelectedImg([ready])
}
const blobToBase64 = blob => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  } */
  const responseFacebook = (response) => {
    const userReady = {
        email: response.email,
accessToken: response.accessToken,
data_access_expiration_time: response.data_access_expiration_time,
expiresIn: response.expiresIn,
graphDomain: response.graphDomain,
name: response.name,
picture: response.picture.data.url,
userID: response.userID
    }
    sendUserFacebook(userReady);
  }
  const sendUserFacebook = (user)=> {
   
console.log(user)
addFacebook({
    variables:{
        input: user
    }}).then(async data=>{
        console.log(data);
        if(data.data.loginFacebook.status){
            await localStorage.setItem('token', data.data.loginFacebook.token)
            await localStorage.setItem('user',JSON.stringify( {email: data.data.loginFacebook.email, img: data.data.loginFacebook.url,
                name: data.data.loginFacebook.name}));
                userContext.logInF();
                navigate('/');
        }

    }).catch(e=>{
        userContext.setSnackBar({state: true, message: e.message, type: 'error'})

    })
  }
    const getToken = async (form) => {
        try {
          //  console.log('solicitando account');
          //  console.log(form);
            setForm(form);
            await addAccount({
                variables:{
                    input: {terms: true, email: form.Email, name: form.User, password: form.Password, profileUrl: ''}
                }}).then(data=>{
               //     console.log(data);
                        if(data.data) {
                            setData(data.data)
                            setInsertCode(true);
                    }

                }).catch(e=>{
                    userContext.setSnackBar({state: true, message: e.message, type: 'error'})

                })
                
           
                //aqui se hcae el cambio de step

        } catch (e) {
            //alertAll(e.graphQLErrors[0].message);
            console.log(e.message)
            userContext.setSnackBar({state: true, message: e.message, type: 'error'})
        }
    }
  
   const login = (data)=> {
    if(loadingLogIn) return;
    loginMut({
        variables:{
            input: {email: data.Email, password: data.Password}
        }}).then(async ({data}) =>{ 
              //  console.log(data)
                if(data.logIn.status){
                    await localStorage.setItem('token', data.logIn.token)
                    await localStorage.setItem('user',JSON.stringify( {email: data.logIn.email, img: data.logIn.profileUrl,
                        name: data.logIn.name}));
                        userContext.logInF();
                        navigate('/');
                } else {

                }
            }).catch(e=>{
            console.log(e.message)
            userContext.setSnackBar({state: true, message: e.message, type: 'error'})

        })
   }
  return (
        <div className='contentBodyLogin'>
            
            <div className='contentHeadLogin'>
                <div className='contentReturn'>

            <div className='sectionReturn' onClick={()=> navigate('/')}>
                <img src={returnPage} className='sectionReturn' alt="close" />
            </div>
            {
                Login ? <div className='title'>
                Inicio de sesion
            </div> : <div className='title'>
                Registro
            </div>
            }
            
                </div>
                {
                Login ?
            <button className='signUpButton' onClick={()=>setLogin(false)}>
                Registrate aquí
            </button>:<button className='signUpButton' onClick={()=>setLogin(true)}>
                Ingresa aquí
            </button> }
            </div>
        <div className='modalLogin'>
            {
                (Login || InsertCode)  && <div className='sectionIcon'>
                <img src={icono} className='closeAction' alt="close"  />
                </div>
            }
            {
            picture && <PictureOptionsComponent setPicture={setPicture} imgs={imgs} setImgs={setImgs} />
        }
  
        {
            Login ?
            (<div className='sectionLogin'>
              
            <form onSubmit={handleSubmit((data)=>login(data))}>
            
            <div className='sectionImputs'>
                <input type='email' {...register("Email")} autoComplete="new-password" className='inputLogin' placeholder='Email'></input>
                <input type='password' {...register("Password")} autoComplete="new-password" className='inputLogin'  placeholder='Contraseña'></input>
            </div>
           
            <div className='sectionFinish'>
                <div className='contentBtn'>
            <button className='btnActionEntryLogin' type='submit'>Entrar { loadingLogIn && 
        <div className="loaderButton"></div>
    }</button>    
                </div>
                <div className='containerLine'>
                    <div className='line'></div>
                    <div className='textInLine'>o</div>
                    <div className='line'></div>
                </div>
                
                
                <FacebookLogin
          appId={environment.appFacebookId}
          fields="name,email,picture"
          scope="public_profile,email"
          callback={responseFacebook}
          cssClass="buttonSignIn"
          textButton={"Ingresa con facebook"}
          autoLoad={false}
          isMobile={false}
          redirectUri={'https://www.expiweb.com/login'}
        />
               {/*  <div className='buttonSignIn' onClick={()=>setLogin(false)}>
                    <img src={sobre} className='imgSignIn' alt="sobre" />
                    <span className='textButtonSignIn'>Correo electronico</span>
                </div> */}
            </div>
            </form>
            </div>
            ):
            (
                
                    !InsertCode ? 
                
            <div className='sectionNewUser'>
                
            <form onSubmit={handleSubmit((data)=>{ getToken(data)})}>
            <div className='sectionIcon'>
                {
                    selectedimg.length == 0 ?
                <div className='containerUpImage' onClick={()=>setPicture(true)}>
                    <img src={upImage} className='upImage' alt="close"  />

                </div> : <div>

                    <ImageProfile image={selectedimg[0].data} onClick={()=>setPicture(true)}  />
                    
                </div>
                }
            </div>
            {
                selectedimg.length > 0 && 
            <div className='optionsImageProfile'>
                <button  className='buttonImageProfile buttonRemove' onClick={()=>{
                    setImgs([])
                    setSelectedImg([])
                }}>
                                        <img src={deletIcon} className='iconOptions' alt="close"  />

                    Remover</button>
                <button  className='buttonImageProfile' onClick={()=>setPicture(true)}>
                                        <img src={changeIcon} className='iconOptions' alt="close"  />
                    Cambiar</button>
            </div>
            }
            <div className='sectionImputs'>
                <input type='text' {...register("User")}  className='inputLogin' placeholder='Nombre' autoComplete="new-password"></input>
                <input type='email' {...register("Email")}  className='inputLogin'  placeholder='Correo'></input>
                <input type='password' {...register("Password")} className='inputLogin'  placeholder='Contraseña'></input>
                <input type='password' {...register("RepeatPassword")} className='inputLogin'  placeholder='Repetir Contraseña'></input>
            </div>
            <div className='sectionFinish'>
                <div className='contentBtn'>
            <button className='btnActionEntryLogin' type='submit' >
                Crear { loadingAddAccount && 
        <div className="loaderButton"></div>
    }
                </button>    
                </div>
                <div className='contentBtn'>
            <button className='btnActionEntryLoginOut' onClick={()=>setLogin(true)}>Volver</button> 

            </div>
            <div className='contetentBtnRead'>
            
            <button className='btnContentRead' onClick={()=>navigate('/tyc')}>Terminos y Condiciones</button> 
            <button className='btnContentRead' onClick={()=>navigate('/privacy')}>Aviso de privacidad</button> 
            </div>
            </div>
            </form>
            </div> : 
            <SetCodeComponent imgSelected={selectedimg} close={()=>setLogin(true)} token={data.authAccount.message} form={form} />
            )
        }
                
              
        </div>
        </div>
   
  )
}


export default LoginNewUser
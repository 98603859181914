import React, {useContext, useEffect} from 'react'
import './SetCodeComponent.css';
import { useForm } from "react-hook-form";
import { queryVerifyEmail, queryUpdateUser } from '../queries/User';
import { useMutation } from '@apollo/client';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const SetCodeComponent = ({close, token, imgSelected, form}) => {
    const navigate = useNavigate();

    const {register, handleSubmit}= useForm();
    
    const [sendCode, { data: dataVerify, loading: loadingVerify }]= useMutation(queryVerifyEmail);
    const [updateM, { loading: loadingUpdate, error:errorUp, data: dataUp}] = useMutation(queryUpdateUser)
    const userContext = useContext(AuthContext);
    
    const dataUpd = async(url = '') => {
        console.log(form)
        await localStorage.setItem('user',JSON.stringify( {email: form.Email, img: url,
            name: form.User}));
            userContext.logInF();
            navigate('/');


    }
   
    const upFile = async(urlPre)=> {
        console.log('upFile');
        const blob = await b64ToBlob();
        //console.log(data.data.getUrlPost.url);
        const status = await fetch(urlPre, {
            method: "PUT",
            body: blob,
        });
        //setLoadingAll(false);
        console.log(status.url)
        let url = status.url.split('?')[0];
        updateUser(url)
        /* setImgs(imgs.map((imgn, indexs)=> {
            if(indexs == index) imgn.url = url
            return imgn
        })) */
    }
    const b64ToBlob = async ()=> {
        //console.log(img)
        var byteCharacters = atob(imgSelected[0].data.replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, ''));
        //const byteCharacters = window.atob(encodeURIComponent( img.replace(/\+/g, " ")));
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'image/jpg'});
        return blob
    }
    const updateUser = async (url)=> {
        

        await updateM({
            variables:{
                input: {update: JSON.stringify({
                  
                    profileUrl: url
                                })}
            }}).then((data)=>{
                dataUpd(url);
                userContext.setSnackBar({state: true, message: 'Ahora puedes usar expi', type: 'success'})

            }).catch((e)=>{
                console.log(e)
                userContext.setSnackBar({state: true, message: e.message, type: 'error'})

            })
    }
    const sendCodeUser = async (form)=> {
        try{
            console.log(token);
            console.log(form);
            await sendCode({
                variables:{
                    input: {token: token , code: form.Code}
                }}). then((data)=>{
                    console.log(imgSelected);
                    console.log(data.data.verifyEmail.preSignedEmail);
                    localStorage.setItem('token', data.data.verifyEmail.message)
                    
                    if(imgSelected.length > 0) {
        
                        upFile(data.data.verifyEmail.preSignedEmail)  
                    } else {
                        console.log('imgSelectedcen 0')
                        dataUpd();
                        navigate('/');
                    }
                }).catch((e)=>{

                    userContext.setSnackBar({state: true, message: e.message, type: 'error'})

                })
            } catch (e) {
                console.log(e)
            }
    }
  return (
    <div>
                <div className='headerLogin'>
                Verifica tu correo
            </div>
            <form className='formInputs' onSubmit={handleSubmit((data)=>{sendCodeUser(data)})}>
            <div className='sectionImputs'>
                <input type='text' {...register("Code")}  className='inputLogin' placeholder='Codigo'></input>
                
            </div>
            <div className='sectionFinish'>
                <div className='contentBtn'>
            <button className='btnActionEntryLogin' type='submit' >Enviar { loadingVerify && 
        <div className="loaderButton"></div> 
            }</button>    
                </div>
                <div className='contentBtn'>
            <button className='btnActionEntryLoginOut' onClick={close}>Cancelar</button>    
            </div>
            </div>
            </form>
            </div> 
  )
}

export default SetCodeComponent
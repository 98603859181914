const origin = window.location.origin;
let customEnv = "local";
if (origin.includes("localhost")) {
  customEnv = "dev";
} else if (origin.includes("expiweb.com")) {
  customEnv = "prod";
}

export const customEnvM = customEnv;
export const envSwitcher = {
  local: {
    appFacebookId: "5322797174444196",
    urlApi: 'http://localhost:4000/graphql'
    
},
dev: {
    appFacebookId: "5322797174444196",
    urlApi: 'http://localhost:4000/graphql'
    
},
prod: {
    appFacebookId: "1566427400438310",
    urlApi: 'https://api.expiweb.com/graphql'
  },
}[customEnv];
console.log(envSwitcher)

export const environment = {
    appFacebookId: envSwitcher.appFacebookId /* https://soranomina-back.azurewebsites.net */,
   
  };
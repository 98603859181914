import React from 'react'
import './ContentRead.css'
const RemoveData = () => {
  return (
    <div>
        <div className='titleRead'>
            Remover cuenta
        </div>
        <div className='contentRead'>
          Para eliminar tu cuenta y toda tu informacion de la plataforma en necesario enviar un correo a hola@expiweb.com con el asunto de "ELIMINAR CUENTA",
          la cuenta debe coincidir con la direccion de donde envies el correo.   
</div>
       
    </div>
  )
}

export default RemoveData